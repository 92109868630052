/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import "./styles.scss";
import SecuLogo from "../../assets/icons/secu-logo.svg";
import { NavLink } from "react-router-dom";
import SecuFooter from "../../assets/imgs/secu-footer.png";
import SecuInstagram from "../../assets/icons/secu-instagram.svg";
import SecuTwitter from "../../assets/icons/secu-twitter.svg";
import SecuFacebook from "../../assets/icons/secu-facebook.svg";
import SecuLinkedin from "../../assets/icons/secu-linkedin.svg";

const Footer = () => {

  return (
    <footer className={"bg-secondary-500 sm:pb-10 pb-4 relative"}>
      <div className={"container lg:pt-24 pt-2"}>
        <div className={"w-full grid lg:grid-cols-5 grid-cols-1 lg:text-left text-center"}>
          <div className={"w-full lg:col-span-3 flex flex-col justify-between"}>
            <div className="lg:block flex flex-col items-center lg:mt-0 mt-10">
              <a href={"/#"}>
                <img
                  className={"h-[32px]"}
                  src={SecuLogo}
                  alt={"Logo"}
                />
              </a>
            </div>
            <div className="flex gap-x-4">
              <a href={"/#"}>
                <img
                  className={"h-[24px]"}
                  src={SecuInstagram}
                  alt={"Logo"}
                />
              </a>
              <a href={"/#"}>
                <img
                  className={"h-[24px]"}
                  src={SecuTwitter}
                  alt={"Logo"}
                />
              </a>
              <a href={"/#"}>
                <img
                  className={"h-[24px]"}
                  src={SecuFacebook}
                  alt={"Logo"}
                />
              </a>
              <a href={"/#"}>
                <img
                  className={"h-[24px]"}
                  src={SecuLinkedin}
                  alt={"Logo"}
                />
              </a>
            </div>
          </div>
          <div className="w-full lg:col-span-2 relative z-[200]">
            <div className={"w-full grid lg:grid-cols-3 grid-cols-1 lg:text-left text-center"}>
              <div className={"w-full lg:mt-0 mt-20 lg:col"}>
                <div className="text-body-md text-body-lg">
                  <NavLink to={"/"}>
                    <p className="text-gray-500 font-medium mb-3">Home</p>
                  </NavLink>
                  <NavLink to={"/about"}>
                    <p className="text-gray-500 font-medium mb-3">About Us</p>
                  </NavLink>
                  <NavLink to={"/industries"}>
                    <p className="text-gray-500 font-medium mb-3">Industries</p>
                  </NavLink>
                  <NavLink to={"/about"}>
                    <p className="text-gray-500 font-medium mb-3">Careers</p>
                  </NavLink>
                  <NavLink to={"/"}>
                    <p className="text-gray-500 font-medium mb-3">Privacy policy</p>
                  </NavLink>
                </div>
              </div>
              <div className={"w-full lg:mt-0 mt-20 lg:col"}>
                <div className="text-body-md text-body-lg">
                  <NavLink to={"/"}>
                    <p className="text-gray-500 font-medium mb-3">Border Control, Policies & Security Agencies</p>
                  </NavLink>
                  <NavLink to={"/"}>
                    <p className="text-gray-500 font-medium mb-3">Government Bodies & Ministries</p>
                  </NavLink>
                  <NavLink to={"/"}>
                    <p className="text-gray-500 font-medium mb-3">Industry, Utilities & Mobility</p>
                  </NavLink>
                </div>
              </div>
              <div className={"w-full pl-12 lg:mt-0 mt-20 lg:col"}>
                <div className="text-body-md text-body-lg">
                  <NavLink to={"/"}>
                    <p className="text-gray-500 font-medium mb-3">Defense & Space</p>
                  </NavLink>
                  <NavLink to={"/"}>
                    <p className="text-gray-500 font-medium mb-3">Healthcare</p>
                  </NavLink>
                  <NavLink to={"/"}>
                    <p className="text-gray-500 font-medium mb-3">Cloud Solutions</p>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-10">
        <div className={"border-t container mt-6 border-t-white xl:!px-0 pt-2"}>
          <div className="lg:grid lg:grid-cols-2 items-center lg:justify-between flex flex-col lg:my-0 my-10">
            <p className="text-white font-bold text-[16px]">Copyright © 2023 Secugame</p>
          </div>
        </div>
      </div>
      <img className="h-[344px] absolute bottom-0 lg:left-[15rem]" src={SecuFooter} alt="SecuFooter" />
    </footer>
  );
};

export default Footer;
