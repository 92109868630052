import React from "react";
import "./styles.scss";

const SecuButton = (props) => {
  const { text = "Button", state = "PRIMARY", cssClass = "", size = "MEDIUM", ...rest } = props;
  return (
    <div className={size === "SMALL" ? "relative w-[8rem]" : size === "MEDIUM" ? "relative w-[10rem]" : "relative w-[12rem]"}>
      <button className={state === "PRIMARY" ? "secu-button" : state === "SECONDARY" ? "secu-button-black" : "secu-button-white"} {...rest}>
        <span className={`arrow ${size}`}></span>
        <span className="arrow-two"></span>
        <span className={`arrow-cap`}></span>
        <span className="arrow-cap-two"></span>
        <span className={`absolute ${cssClass}`}>{text}</span>
      </button>
    </div>
  );
};

export default SecuButton;