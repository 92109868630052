import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import Aos from "aos";
import Home from "./views/Home";
import About from "./views/About";
import ContactUs from "./views/ContactUs";
import Industries from "./views/Industries";
import Services from "./views/Services";

function App() {
  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 1000,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contact" element={<ContactUs />} />
          <Route exact path="/industries" element={<Industries />} />
          <Route exact path="/services" element={<Services />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
