import React from "react";
import "./styles.scss";

const StaticButton = (props) => {
  const { text = "Button", state = "PRIMARY", ...rest } = props;
  return (
    <div className="relative">
      <button className={state === "PRIMARY" ? "static-button-primary" : "static-button-secondary"} {...rest}>
        {text}
      </button>
    </div>
  );
};

export default StaticButton;