import React, { useEffect } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const Main = ({ className, children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={`${className ? className : ""}`}>
      <NavBar className={className} />
      <div className="bg-secondary-500">{children}</div>
      <Footer />
    </div>
  );
};

export default Main;
