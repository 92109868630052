import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import SecuMenuOpen from "../../assets/imgs/secu-close.png";
import SecuMenuClose from "../../assets/imgs/secu-menu.png";
import SecuLogo from "../../assets/icons/secu-logo.svg";
import SecuArrows from "../../assets/imgs/secu-arrows.png";
import "./styles.scss";
import { NavLink } from "react-router-dom";
import { navs } from "../../utils/navs";
import StaticButton from "../Button/StaticButton";

const NavBar = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className={`nav-bar-floated w-full bg-secondary-500`}>
      <div className={"container"}>
        <div className={"flex h-auto sm:pt-12 sm:pb-0 pb-3 pt-3 w-full z-[2000] relative"}>
          <div className={"flex lg:w-6/12 w-5/12 sm:items-start items-center"}>
            <div className={"flex-shrink-0"}>
              <NavLink to={"/"}>
                <img
                  className={"lg:h-[26px] h-[18px]"}
                  src={SecuLogo}
                  alt={"Logo"}
                />
              </NavLink>
            </div>
          </div>
          <div className={"flex items-center lg:w-4/12 w-5/12"}>
            <div className={"w-full hidden md:block"}>
              <div className={"w-full lg:ml-10 flex items-baseline flex-wrap gap-y-4"}>
                {navs.map((n, i) => (
                  <div className="w-1/2 relative" key={n.name}>
                    <NavLink
                      className={"nav_style"}
                      to={n.route}
                    >
                      <span className="arrow"></span>
                      <span className="arrow-two"></span>
                      <span className="arrow-cap"></span>
                      <span className={`absolute lg:text-body-lg text-body-md`}>{n.name}</span>
                      <img
                        className={"absolute"}
                        src={SecuArrows}
                        alt={"Logo"}
                      />
                    </NavLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={"flex justify-end w-2/12"}>
            <div className={"hidden md:block"}>
              <StaticButton
                state={"PRIMARY"}
                text={"Let’s Talk"}
                type={"button"}
              />
            </div>
          </div>
          <div className={"-mr-2 flex md:hidden"}>
            <button
              onClick={() => setIsOpen(!isOpen)}
              type={"button"}
              className={
                "inline-flex items-center justify-center p-2 focus:outline-none"
              }
              aria-controls={"mobile-menu"}
              aria-expanded={"false"}
            >
              <img src={SecuMenuClose} alt={"menu"} className={"h-8"} />
            </button>
          </div>
        </div>
      </div>

      <Transition
        show={isOpen}
        enter={"transition ease-out duration-100 transform"}
        enterFrom={"opacity-0 scale-95"}
        enterTo={"opacity-100 scale-100"}
        leave={"transition ease-in duration-75 transform"}
        leaveFrom={"opacity-100 scale-100"}
        leaveTo={"opacity-0 scale-95"}
        className={"absolute w-full bg-[#211F1F] h-[40vh] shadow top-0 z-[20000]"}
      >
        <div className={"md:hidden"} id={"mobile-menu"}>
          <div className={"flex md:hidden justify-between py-4 mx-10 items-center border-b border-b-[0.1px] border-b-[#414040]"}>
            <img
              className={"h-6"}
              src={SecuLogo}
              alt={"Logo"}
            />
            <button
              onClick={() => setIsOpen(!isOpen)}
              type={"button"}
              className={
                "inline-flex items-center justify-center p-2 focus:outline-none"
              }
              aria-controls={"mobile-menu"}
              aria-expanded={"false"}
            >
              <img src={SecuMenuOpen} alt={"menu"} className={"h-8"} />
            </button>
          </div>
          <div className={"px-10 pt-10 pb-3 space-y-1 sm:px-3"}>
            {navs.map((n, i) => (
              <div key={i + n}>
                <NavLink
                  className={"nav_style_mobile"}
                  key={n.name}
                  to={n.route}
                  onClick={(e) => { setIsOpen(!isOpen); }}
                >
                  <span className={`absolute lg:text-body-lg text-body-md`}>{n.name}</span>
                  <span className="arrow"></span>
                </NavLink>
              </div>
            ))}
            <div className={"pt-10"}>
              <StaticButton
                state={"PRIMARY"}
                text={"Let’s Talk"}
                type={"button"}
              />
            </div>
          </div>
        </div>
      </Transition>
    </nav>
  );
};

export default NavBar;
