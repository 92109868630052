import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import Main from "../../layout/Main";
import SecuHandshake from "../../assets/imgs/secu-handshake.png"
import { useNavigate } from 'react-router-dom'
import "./styles.scss";
import { INDUSTRIES } from "../../utils/constants";
import SecuNetwork from "../../assets/imgs/secu-network.png";
import SecuStorage from "../../assets/imgs/secu-storage.png";
import SecuDataCenter from "../../assets/imgs/secu-data-center.png";
import SecuLeftRedBg from "../../assets/imgs/secu-left-red-bg.png";
import SecuRuby from "../../assets/imgs/secu-ruby.png";
import SecuSetting from "../../assets/imgs/secu-setting.png";
import SecuDart from "../../assets/imgs/secu-dart.png";
import SecuCost from "../../assets/imgs/secu-cost.png";
import SecuBulkRuby from "../../assets/imgs/secu-bulk-ruby.png";
import SecuGrill from "../../assets/imgs/secu-grill.png";
import SecuNext from "../../assets/imgs/secu-next.png";
import SecuPrev from "../../assets/imgs/secu-prev.png";
import SecuPlay from "../../assets/gif/secu-play.gif";
import SecuButton from "../../components/SecuButton";
import SecuArrows from "../../assets/imgs/secu-arrows.png";

const Home = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const industryEnter = (index) => {
    setActiveIndex(index);
  }

  const industryLeave = (index) => {
    setActiveIndex(0);
  }

  const scrollNext = () => {
    const element = document.getElementById("products");
    element.scrollBy({
      top: 0,
      left: 1000,
      behavior: "smooth",
    });
    setScrollLeft(element.scrollLeft);
  }

  const scrollPrev = () => {
    const element = document.getElementById("products");
    element.scrollBy({
      top: 0,
      left: -1000,
      behavior: "smooth",
    });
    setScrollLeft(element.scrollLeft);
  }

  return (
    <Main>
      <Helmet>
        <title>Home | secugame</title>
        <meta name="keywords" content="New Era" />
      </Helmet>
      <div className={"min-h-screen h-full overflow-x-hidden"}>
        <section className="sm:pt-20 pt-28">
          <div className="container relative z-[200]">
            <div className="flex flex-wrap relative">
              <div className="w-full">
                <div className="w-full">
                  <h4 className="lg:text-[28px] sm:text-[20px] text-[16px] text-white font-light">Digital Mastery</h4>
                  <h1 className="lg:text-[42px] sm:text-[32px] text-[24px] mt-2 text-white">Complete Digital Control<br />and Cyber attack preventions</h1>
                  <p className="lg:text-[18px] sm:text-[14px] text-[12px] mt-2 text-white font-light">Secugame provides cutting-edge IT solutions that protect your data, applications <br className="sm:block hidden" />and identities cyber threats, and empowers you to have full autonomy over your<br className="sm:block hidden" />digital infrastructure</p>
                  <div className="mt-8">
                    <SecuButton
                      state={"PRIMARY"}
                      text={"Speak with Us"}
                      type={"button"}
                      onClick={() => navigate("/contact")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img className="sm:h-[30rem] h-[15rem] absolute sm:top-[9rem] top-[0rem] bottom-0 right-0" src={SecuPlay} alt="SecuPlay" />
        </section>
        <section className="sm:mt-20 mt-10">
          <div className="flex w-full h-full gap-x-1">
            <div className="w-[20%]">
              <img className="h-full w-full" src={SecuNetwork} alt="SecuNetwork" />
            </div>
            <div className="w-[30%]">
              <img className="h-full w-full" src={SecuStorage} alt="SecuStorage" />
            </div>
            <div className="w-[50%]">
              <img className="h-full w-full" src={SecuDataCenter} alt="SecuDataCenter" />
            </div>
          </div>
        </section>
        <section className="relative">
          <div className="container lg:mb-0 mb-[24px]">
            <div className="lg:mt-[218px] mt-[58px] mt-10 flex flex-wrap relative">
              <div className="md:w-[65%] w-full">
                <div className="w-full relative z-[2000]">
                  <h4 className="lg:text-[28px] sm:text-[20px] text-[16px] text-white font-light">SECURE</h4>
                  <h1 className="lg:text-[52px] sm:text-[42px] text-[24px] mt-2 text-white">Protect Your Digital Assets with Secugame</h1>
                  <p className="lg:text-[20px] sm:text-[16px] text-[12px] mt-2 text-white font-light">We strongly believe in building secure tools for a super-connected world. Our reliable products and personalized guidance boost your digital freedom, giving you the tools to expand your operations to unprecedented heights.</p>
                  <div className="mt-8 flex gap-x-8">
                    <SecuButton
                      state={"PRIMARY"}
                      text={"Learn more"}
                      type={"button"}
                      onClick={() => navigate("/about")}
                    />
                    <SecuButton
                      state={"SECONDARY"}
                      text={"Contact Us"}
                      type={"button"}
                      onClick={() => navigate("/contact")}
                    />
                  </div>
                </div>
              </div>
              <div className="md:w-[35%] w-full sm:mt-0 mt-20">
                <div className="w-full">
                  <img className="lg:h-[474px] sm:h-[274px] h-[474px]" src={SecuRuby} alt="SecuRuby" />
                </div>
              </div>
            </div>
          </div>
          <div className="container sm:pb-[62px] sm:border-b-[1px] border-primary-500 mb-4">
            <div className="offers">
              <div className="offer sm:pt-[64px] pt-[54px] sm:pb-[42px] pb-[32px] sm:pl-[38px] pl-[28px] sm:pr-[33px] pr-[23px]">
                <div>
                  <img className="sm:h-[71px] h-[51px] relative z-[200]" src={SecuHandshake} alt="SecuHandshake" />
                </div>
                <p className={"sm:text-[26px] text-[16px] relative z-[200] text-white font-semibold mt-4"}>Reliable</p>
                <p className={"sm:text-[18px] text-[12px] relative z-[200] text-white"}>Trust our proven track record of defending organisations of all sizes from online security threats. Our global reputation becomes your safety net.</p>
              </div>
              <div className="offer sm:pt-[64px] pt-[54px] sm:pb-[42px] pb-[32px] sm:pl-[38px] pl-[28px] sm:pr-[33px] pr-[23px]">
                <dov>
                  <img className="sm:h-[71px] h-[51px] w-fit relative z-[200]" src={SecuSetting} alt="SecuSetting" />
                </dov>
                <p className={"sm:text-[26px] text-[16px] relative z-[200] text-white font-semibold mt-4"}>Efficient</p>
                <p className={"sm:text-[18px] text-[12px] relative z-[200] text-white"}>Never have to worry about productivity loss as you streamline your IT security processes and optimize your defenses with Secugame.</p>
              </div>
              <div className="offer sm:pt-[64px] pt-[54px] sm:pb-[42px] pb-[32px] sm:pl-[38px] pl-[28px] sm:pr-[33px] pr-[23px]">
                <div>
                  <img className="sm:h-[71px] h-[51px] w-fit relative z-[200]" src={SecuDart} alt="SecuDart" />
                </div>
                <p className={"sm:text-[26px] text-[16px] relative z-[200] text-white font-semibold mt-4"}>Fast</p>
                <p className={"sm:text-[18px] text-[12px] relative z-[200] text-white"}>Accelerate your growth as you transition from slow, outdated operations to a speedy, automated process that takes your organisation from good to great.</p>
              </div>
              <div className="offer sm:pt-[64px] pt-[54px] sm:pb-[42px] pb-[32px] sm:pl-[38px] pl-[28px] sm:pr-[33px] pr-[23px]">
                <div>
                  <img className="sm:h-[71px] h-[51px] w-fit relative z-[200]" src={SecuCost} alt="SecuCost" />
                </div>
                <p className={"sm:text-[26px] text-[16px] relative z-[200] text-white font-semibold mt-4"}>Cost-effective</p>
                <p className={"sm:text-[18px] text-[12px] relative z-[200] text-white"}>Boost your revenue as you cut costs from repeatedly putting out fires of cyber threats internally and externally.</p>
              </div>
            </div>
          </div>
          <img className="h-[60rem] top-[8rem] left-[-12rem] absolute" src={SecuLeftRedBg} alt="SecuLeftRedBg" />
        </section>
        <section className="relative">
          <div className="lg:mt-[253px] mt-[53px] mb-[97px] container">
            <div className="w-full flex flex-col justify-center items-center">
              <h4 className="max-w-[774px] lg:text-[52px] sm:text-[32px] text-[20px] text-white font-bold text-center">
                One-Stop Security Solutions for Various Industries
              </h4>
              <img className="lg:h-[720px] sm:h-[520px] h-[320px] relative z-[200]" src={SecuBulkRuby} alt="SecuCost" />
            </div>
          </div>
          <img className="h-[50rem] top-[12rem] bottom-0 left-[-20rem] right-0 mx-auto absolute" src={SecuLeftRedBg} alt="SecuLeftRedBg" />
        </section>
        <section className="container">
          <div className="w-full flex justify-center items-center">
            <div className="max-w-[906px]">
              <p className="text-[20px] lg:ml-[24px] text-primary-500 font-light">INDUSTRIES</p>
              <div className="w-full sm:flex mt-[34px]">
                <div className="sm:block hidden sm:w-5/12 lg:pl-[24px]">
                  <div className="industries max-w-[300px]">
                    {INDUSTRIES.map((item, index) => <div key={item?.name} className={`relative mb-[32px] industry ${activeIndex === index ? 'active' : ''}`} onMouseEnter={() => industryEnter(index)} onMouseLeave={() => industryLeave(index)}>
                      <p className={"lg:text-[24px] text-[20px] text-white"}>{item?.name}</p>
                    </div>
                    )}
                  </div>
                </div>
                <div className="sm:hidden block sm:w-5/12 lg:pl-[24px]">
                  <div className="industries-mobile">
                    {INDUSTRIES.map((item, index) => <div key={item?.name} className={`relative mb-[12px] industry ${activeIndex === index ? 'active' : ''}`} onMouseEnter={() => industryEnter(index)} onMouseLeave={() => industryLeave(index)}>
                      <div className="flex">
                        {activeIndex === index ? <img
                          src={SecuArrows}
                          alt={"Logo"}
                          className="h-4 mr-2"
                        /> : <></>}
                        <p className={"text-[12px] text-white font-medium max-w-[170px]"}>{item?.name}</p>
                      </div>
                    </div>
                    )}
                  </div>
                </div>
                <div className="sm:w-7/12 sm:border-l-[1px] sm:border-t-[0px] border-t-[1px] border-primary-500 sm:pl-[37px] sm:pt-0 pt-6">
                  <img className="lg:h-[268px] secu-transition" src={INDUSTRIES[activeIndex]?.image} alt="industry" />
                  <p className={"mt-[24px] text-white secu-transition lg:text-[20px] sm:text-[16px] text-[12px]"}>{INDUSTRIES[activeIndex]?.description}</p>
                  <div className="sm:mt-[34px] mt-[24px] mb-[44px]">
                    <SecuButton
                      state={"SECONDARY"}
                      text={"Explore this Sector"}
                      type={"button"}
                      size={"LARGE"}
                      onClick={() => navigate("/industries")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="lg:mt-[276px] mt-[76px] container">
          <div className="flex flex-wrap relative mb-[49px]">
            <div className="w-full relative z-[200]">
              <h4 className="lg:text-[52px] sm:text-[32px] text-[16px] text-white font-light">Market-Leading Products</h4>
              <h1 className="lg:text-[80px] sm:text-[50px] text-[24px] text-primary-500 font-thin">Tailored For Your Defence</h1>
              <p className="lg:text-[20px] sm:text-[16px] text-[12px] mt-2 text-white font-light max-w-[827px]">Our modern architecture provides ready-to-use products and expert consultancy. We empower you to meet the highest security standards in digitisation projects, propelling your digital transformation ahead of competitors and potential threats.</p>
            </div>
            <img className="sm:h-[224px] h-[64px] absolute sm:top-0 top-[-10rem] bottom-0 right-0 my-auto" src={SecuGrill} alt="SecuGrill" />
          </div>
          <div className="flex justify-between items-center navigations mb-[43px]">
            <div className={`nav ${scrollLeft === 0 ? 'prev' : 'next'}`} onClick={scrollPrev}>
              <img className="h-[24px]" src={SecuPrev} alt="SecuPrev" />
            </div>
            <div className={`nav ${scrollLeft === 0 ? 'next' : 'prev'}`} onClick={scrollNext}>
              <img className="h-[24px]" src={SecuNext} alt="SecuNext" />
            </div>
          </div>
          <div className="products" id="products">
            <div className="product sina-work pb-[42px] pl-[38px] pr-[33px]">
              <button className="prod-btn">SINA WORKSTATIONS</button>
              <p className={"sm:text-[22px] text-[16px] sm:max-w-[350px] max-w-[250px] relative z-[200] text-white font-semibold mt-4"}>All-in-one solution to process classified data</p>
              <p className={"sm:text-[16px] text-[12px] relative z-[200] text-white"}>The secure mobile solution for remote and on-the-go work.</p>
              <div className="mt-[22px] mb-[38px]">
                <SecuButton
                  state={"WHITE"}
                  text={"Learn more"}
                  type={"button"}
                  onClick={() => navigate("/services")}
                />
              </div>
            </div>
            <div className="product bocoa pb-[42px] pl-[38px] pr-[33px]">
              <button className="prod-btn">BOCOA</button>
              <p className={"sm:text-[22px] text-[16px] sm:max-w-[350px] max-w-[250px] relative z-[200] text-white font-semibold mt-4"}>Digital ID-Tool</p>
              <p className={"sm:text-[16px] text-[12px] relative z-[200] text-white"}>Digitally bundle all information for a quick ID check</p>
              <div className="mt-[22px] mb-[38px]">
                <SecuButton
                  state={"WHITE"}
                  text={"Learn more"}
                  type={"button"}
                  onClick={() => navigate("/services")}
                />
              </div>
            </div>
            <div className="product easygate pb-[42px] pl-[38px] pr-[33px]">
              <button className="prod-btn">EASYGATE</button>
              <p className={"sm:text-[22px] text-[16px] sm:max-w-[350px] max-w-[250px] relative z-[200] text-white font-semibold mt-4"}>Cloud Operating</p>
              <p className={"sm:text-[16px] text-[12px] relative z-[200] text-white"}>Automated border control that meets the highest security standards.</p>
              <div className="mt-[22px] mb-[38px]">
                <SecuButton
                  state={"WHITE"}
                  text={"Learn more"}
                  type={"button"}
                  onClick={() => navigate("/services")}
                />
              </div>
            </div>
            <div className="product secstack pb-[42px] pl-[38px] pr-[33px]">
              <button className="prod-btn">SECUSTACK</button>
              <p className={"sm:text-[22px] text-[16px] sm:max-w-[350px] max-w-[250px] relative z-[200] text-white font-semibold mt-4"}>All-in-one solution to process classified data</p>
              <p className={"sm:text-[16px] text-[12px] relative z-[200] text-white"}>The secure mobile solution for remote and on-the-go work.</p>
              <div className="mt-[22px] mb-[38px]">
                <SecuButton
                  state={"WHITE"}
                  text={"Learn more"}
                  type={"button"}
                  onClick={() => navigate("/services")}
                />
              </div>
            </div>
            <div className="product biomectrics pb-[42px] pl-[38px] pr-[33px]">
              <button className="prod-btn">BIOMETRICS</button>
              <p className={"sm:text-[22px] text-[16px] sm:max-w-[350px] max-w-[250px] relative z-[200] text-white font-semibold mt-4"}>Consulting on our biometrics and elD Solutions</p>
              <p className={"sm:text-[16px] text-[12px] relative z-[200] text-white"}>With our 20 years + experience, your biometric projects will be a success</p>
              <div className="mt-[22px] mb-[38px]">
                <SecuButton
                  state={"WHITE"}
                  text={"Learn more"}
                  type={"button"}
                  onClick={() => navigate("/services")}
                />
              </div>
            </div>
            <div className="product sina-com pb-[42px] pl-[38px] pr-[33px]">
              <button className="prod-btn">SINA COMMUNICATOR</button>
              <p className={"sm:text-[22px] text-[16px] sm:max-w-[350px] max-w-[250px] relative z-[200] text-white font-semibold mt-4"}>The versatile multi-crypto phone</p>
              <p className={"sm:text-[16px] text-[12px] relative z-[200] text-white"}>Novel SECRET-level communication beyond the ISDN era.</p>
              <div className="mt-[22px] mb-[38px]">
                <SecuButton
                  state={"WHITE"}
                  text={"Learn more"}
                  type={"button"}
                  onClick={() => navigate("/services")}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="lg:mt-[149px] mt-[49px] protect lg:h-[650px] sm:h-[450px] h-[300px] flex flex-col justify-end items-center text-center">
          <div className="sm:max-w-[982px] max-w-[382px] sm:mb-[71px]">
            <div className="w-full">
              <h1 className="lg:text-[52px] sm:text-[42px] text-[22px] mt-2 text-white">Protect your Digital Assets Safely</h1>
              <p className="lg:text-[20px] sm:text-[16px] text-[12px] mt-2 text-white font-light">Let’s help you build a cyber defence system that brings money in and keeps hackers out forever. Contact us today to discuss your IT security needs and partnership opportunities.</p>
              <div className="mt-8 flex justify-center">
                <SecuButton
                  state={"PRIMARY"}
                  text={"Learn more"}
                  type={"button"}
                  onClick={() => navigate("/industries")}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Main>
  );
};

export default Home;