import React from "react";
import { Helmet } from 'react-helmet';
import Main from "../../layout/Main";
import { useNavigate } from 'react-router-dom'
import "./styles.scss";
import SecuServices from "../../assets/imgs/secu-services.png"
import { SERVICES } from "../../utils/constants";

const Services = () => {
  const navigate = useNavigate();
  return (
    <Main>
      <Helmet>
        <title>Services | secugame</title>
        <meta name="keywords" content="New Era" />
      </Helmet>
      <div className={"min-h-screen h-full overflow-x-hidden"}>
        <section className="container mt-[80px]">
          <p className="lg:text-[52px] text-[42px] text-white">Our Services</p>
        </section>
        <section className="relative">
          <div className="bg-primary-500 lg:h-[448px] sm:h-[308px] h-[408px]">
            <div className="container h-full">
              <div className="w-full sm:flex h-full">
                <div className="sm:w-5/12 sm:flex items-end sm:h-full sm:py-[56px] py-[28px]">
                  <p className="sm:w-full w-1/2 text-white lg:text-[32px] sm:text-[22px] text-[12px]">To strengthen your digital infrastructures securely and individually, our portfolio ranges from specific products to general consulting services</p>
                </div>
                <div className="sm:w-7/12">
                  <img className="sm:h-full" src={SecuServices} alt="SecuServices" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container mb-[200px]">
          <div className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-2 w-full gap-x-4">
            {SERVICES.map(s => <div key={s.name} className="sm:h-[298px] h-[238px] bg-white lg:mt-[66px] mt-[36px] sm:pl-[23px] pl-[13px] sm:pb-[17px] pb-[7px] service" onClick={() => navigate("/contact")}>
              <div className="flex justify-between">
                <p className="sm:text-[14px] text-[12px] font-light text-primary-500 pt-[17px] w-[65%]">{s?.name}</p>
                <div className="service-edge">
                  <svg xmlns="http://www.w3.org/2000/svg" width="46.5" height="46.5" viewBox="0 0 46.5 46.5">
                    <g id="Component_111_1" data-name="Component 111 – 1" transform="translate(0.75 0.75)">
                      <circle id="Ellipse_169" data-name="Ellipse 169" cx="22.5" cy="22.5" r="22.5" fill="none" stroke="#e81e49" strokeWidth="1.5" />
                      <g id="Group_167718" data-name="Group 167718" transform="translate(-382.866 -1516.601)">
                        <path id="Path_118086" data-name="Path 118086" d="M6434.177,801.021h12.573l13.557-13.558" transform="translate(-6048.717 747.136)" fill="none" stroke="#e81e49" strokeLinecap="round" strokeWidth="1.5" />
                        <path id="Path_118100" data-name="Path 118100" d="M163.1,18.963h11.559V30.136" transform="translate(236.992 1515.636)" fill="none" stroke="#e81e49" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Path_118101" data-name="Path 118101" d="M163.1,18.963h11.559V30.136" transform="translate(241.546 1511.082)" fill="none" stroke="#e81e49" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              <div className="pr-[23px] mt-10">
                <p className="sm:text-[18px] text-[12px] w-[90%]">{s?.title}</p>
                <p className="mt-[8px] text-gray-500 sm:text-[12px] text-[10px] font-light">{s?.description}</p>
              </div>
            </div>)}
          </div>
        </section>
      </div>
    </Main>
  );
};

export default Services;