import React from "react";
import { Helmet } from 'react-helmet';
import Main from "../../layout/Main";
import "./styles.scss";
import { useNavigate } from 'react-router-dom'
import SecuLeftRedBg from "../../assets/imgs/secu-left-red-bg.png";
import SecuMission from "../../assets/imgs/secu-mission.png"
import SecuPlay from "../../assets/imgs/secu-play.png"
import SecuMeeting from "../../assets/imgs/secu-meeting.png"
import SecuLeftGrill from "../../assets/imgs/secu-left-grill.png"
import SecuRightGrill from "../../assets/imgs/secu-right-grill.png"
import SecuBottomGrill from "../../assets/imgs/secu-bottom-gril.png"
import SecuLogo from "../../assets/icons/secu-logo.svg";
import SecuAward from "../../assets/imgs/secu-award.png"
import SecuTrust from "../../assets/imgs/secu-trust.png"
import SecuExcellence from "../../assets/imgs/secu-excellence.png"
import SecuCollaboration from "../../assets/imgs/secu-collaboration.png"
import SecuOpeness from "../../assets/imgs/secu-openess.png"
import SecuInnovation from "../../assets/imgs/secu-innovation.png"
import SecuButton from "../../components/SecuButton";

const About = () => {
  const navigate = useNavigate();
  return (
    <Main>
      <Helmet>
        <title>About | secugame</title>
        <meta name="keywords" content="New Era" />
      </Helmet>
      <div className={"min-h-screen h-full overflow-x-hidden"}>
        <section className="lg:h-[755px] sm:h-[555px] h-[255px] container relative" id="home">
          <div className="flex items-end h-full">
            <h1 className="lg:text-[88px] sm:text-[68px] text-[24px] mt-2 text-white relative z-[200]">The Leading<br />Cybersecurity Partner<br />for Premier Organizations</h1>
          </div>
          <img className="sm:h-[327px] h-[127px] absolute sm:top-[122px] top-[82px] bottom-0 sm:right-[292px] right-[22px]" src={SecuMission} alt="SecuMission" />
          <img className="h-[50rem] lg:top-[8rem] top-[3rem] left-[5rem] absolute" src={SecuLeftRedBg} alt="SecuLeftRedBg" />
          <div className="absolute sm:top-0 lg:right-0 sm:right-[2rem] right-0 sm:left-auto left-[9rem] sm:bottom-0 bottom-[-3rem] my-auto h-[20px]">
            <p className="lg:text-[28px] sm:text-[18px] text-[14px] font-light text-white">Our mission:</p>
            <p className="lg:text-[28px] sm:text-[18px] text-[14px] font-semibold text-primary-500">Protecting Digital<br />Infrastructures</p>
          </div>
        </section>
        <section className="sm:block hidden h-[551px] relative mt-[142px]">
          <div className="container">
            <div className="w-full flex flex-col items-center justify-center">
              <div className="lg:w-9/12">
                <div className="w-full flex">
                  <div className="w-1/2 relative z-[200]">
                    <img className="lg:h-[422px] h-[322px]" src={SecuPlay} alt="SecuPlay" />
                  </div>
                  <div className="w-1/2 flex flex-col justify-end pb-[41px] relative z-[200]">
                    <h1 className="lg:text-[52px] text-[42px] font-bold text-primary-500">1,000+</h1>
                    <p className="lg:text-[42px] text-[32px] font-semibold text-white">Experts Creating a Secure Digital World</p>
                  </div>
                </div>
              </div>
              <div className="lg:w-9/12">
                <div className="w-full flex">
                  <div className="w-1/2 relative z-[200]">
                    <p className="mt-[26px] max-w-[392px] text-white text-[20px]">Our expertise in vital security covers crucial domains such as cloud, Internet of things, eGovernment, biometrics, and eHealth, ensuring top-tier protection for data, applications, and identities.</p>
                    <div className="mt-[46px]">
                      <SecuButton
                        state={"PRIMARY"}
                        text={"Contact Us"}
                        type={"button"}
                        onClick={() => navigate("/contact")}
                      />
                    </div>
                  </div>
                  <div className="w-1/2 relative z-[200]">
                    <img className="h-[327px]" src={SecuMeeting} alt="SecuMeeting" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img className="h-[225px] absolute left-0 top-0 bottom-0 my-auto" src={SecuLeftGrill} alt="SecuLeftGrill" />
          <img className="h-[502px] absolute right-[-15rem] top-0 bottom-0 my-auto" src={SecuRightGrill} alt="SecuRightGrill" />
          <img className="h-[531px] absolute right-0 left-0 bottom-[-34rem] mx-auto" src={SecuBottomGrill} alt="SecuBottomGrill" />
        </section>
        <section className="sm:hidden block h-[551px] relative mt-[142px]">
          <div className="container">
            <div className="w-full flex flex-col items-center justify-center">
              <div className="w-full">
                <img className="relative w-full z-[200]" src={SecuPlay} alt="SecuPlay" />
                <div className="w-full flex">
                  <div className="w-full relative z-[200]">
                    <p className="text-white text-[12px]">Our expertise in vital security covers crucial domains such as cloud, Internet of things, eGovernment, biometrics, and eHealth, ensuring top-tier protection for data, applications, and identities.</p>
                  </div>
                </div>
                <div className="w-full mt-10 flex flex-col justify-end pb-[41px] relative z-[200]">
                  <h1 className="text-[28px] font-bold text-primary-500">1,000+</h1>
                  <p className="text-[22px] font-semibold text-white">Experts Creating a Secure Digital World</p>
                </div>
                <div className="w-full relative z-[200]">
                  <img className="w-full" src={SecuMeeting} alt="SecuMeeting" />
                </div>
              </div>
            </div>
          </div>
          <img className="h-[125px] absolute left-0 top-[5rem] bottom-0" src={SecuLeftGrill} alt="SecuLeftGrill" />
          <img className="h-[302px] absolute right-[-15rem] top-0 bottom-0" src={SecuRightGrill} alt="SecuRightGrill" />
          <img className="h-[331px] absolute right-0 left-0 bottom-[-54rem] mx-auto" src={SecuBottomGrill} alt="SecuBottomGrill" />
        </section>
        <section className="relative mt-[600px] mb-[97px]">
          <div className="understanding sm:h-[419px] h-[209px]">
            <div className="container">
              <div className="py-[39px]">
                <img
                  className={"lg:h-[26px] h-[18px]"}
                  src={SecuLogo}
                  alt={"Logo"}
                />
                <p className="lg:text-[52px] sm:text-[32px] text-[16px] text-white lg:mt-[61px] mt-[31px]">Understanding</p>
                <h1 className="lg:text-[90px] sm:text-[60px] text-[24px] text-white">SECUGAME NIGERIA</h1>
              </div>
            </div>
          </div>
          <div className="container sm:mt-[52px] mt-[24px] relative">
            <div className="lg:max-w-[779px] max-w-[479px]">
              <p className="lg:text-[20px] sm:text-[16px] text-[12px] text-white">
                Secugame Nigeria in partnership with Secunet Security Networks AG, operates in essential sectors like eHealth, Homeland Security, Industry, Public Authorities, and Defense and space. Our clientele includes federal ministries, major corporations, and global authorities like the EU Commission.<br /><br />Since 1997, Secunet has been a listed company with the endorsement of the Federal Republic of Germany as its IT security partner. In 2022, our sales reached approximately 345 million euros. Giesecke & Devrient GmbH is our primary shareholder.
              </p>
              <img className="lg:h-[399px] h-[299px] sm:block hidden absolute lg:right-[10rem] right-[5rem] lg:top-[-6rem] top-[-12rem]" src={SecuAward} alt="SecuAward" />
            </div>
          </div>
        </section>
        <section className="sm:block hidden lg:mt-[306px] mt-[106px]">
          <div className="container">
            <div className="w-full flex gap-x-12">
              <div className="w-5/12">
                <h2 className="text-white lg:text-[52px] text-[32px]">OUR VALUES</h2>
                <p className="text-white lg:text-[20px] text-[16px] max-w-[421px] mt-[20px]">At Secugame, we are fully devoted to our work, embrace change, and eagerly tackle new challenges. Together, we contribute to our client’s success, guided by shared values that define our highly productive team and reflect our identity.</p>
                <div className="our-value lg:h-[596px] h-[396px] mt-[33px] pl-[41px] pb-[56px] flex items-end">
                  <SecuButton
                    state={"PRIMARY"}
                    text={"Speak with Us"}
                    type={"button"}
                    onClick={() => navigate("/contact")}
                  />
                </div>
              </div>
              <div className="w-7/12 border-l-[1px] border-primary-500 pl-12">
                <div className="w-full flex gap-x-12">
                  <div className="w-1/2">
                    <img className="lg:h-[65px] h-[45px]" src={SecuTrust} alt="SecuTrust" />
                    <p className="mt-[24px] font-semibold lg:text-[20px] text-[16px] text-primary-500">TRUST</p>
                    <p className="text-white lg:text-[18px] text-[12px] mt-[7px]">We uphold honesty, keep our word, and take collective responsibility, fostering trust among ourselves and our clients.</p>
                  </div>
                  <div className="w-1/2">
                    <img className="lg:h-[65px] h-[45px]" src={SecuCollaboration} alt="SecuCollaboration" />
                    <p className="mt-[24px] font-semibold lg:text-[20px] text-[16px] text-primary-500">COLLABORATION</p>
                    <p className="text-white lg:text-[18px] text-[12px] mt-[7px]">Collaboration defines us; we work together, support each other, and serve our clients and society with a shared sense of purpose.</p>
                  </div>
                </div>
                <div className="w-full flex gap-x-12 mt-[69px]">
                  <div className="w-1/2">
                    <img className="lg:h-[65px] h-[45px]" src={SecuOpeness} alt="SecuOpeness" />
                    <p className="mt-[24px] font-semibold lg:text-[20px] text-[16px] text-primary-500">OPENESS</p>
                    <p className="text-white lg:text-[18px] text-[12px] mt-[7px]">Open dialogue fuels our growth; we encourage transparency, embrace learning from everyone, and value respectful communication at all levels.</p>
                  </div>
                  <div className="w-1/2">
                    <img className="lg:h-[65px] h-[45px]" src={SecuExcellence} alt="SecuTrust" />
                    <p className="mt-[24px] font-semibold lg:text-[20px] text-[16px] text-primary-500">EXCELLENCE</p>
                    <p className="text-white lg:text-[18px] text-[12px] mt-[7px]">Excellence drives us; we prioritize optimal solutions, client relationships, and deliver high-quality results through experience and empathy.</p>
                  </div>
                </div>
                <div className="w-full flex gap-x-12 mt-[69px]">
                  <div className="w-1/2">
                    <img className="lg:h-[65px] h-[45px]" src={SecuInnovation} alt="SecuInnovation" />
                    <p className="mt-[24px] font-semibold lg:text-[20px] text-[16px] text-primary-500">INNOVATION</p>
                    <p className="text-white lg:text-[18px] text-[12px] mt-[7px]">Curiosity fuels our progress; we explore new horizons, foster a thirst for knowledge, and pursue innovation with passion and dedication.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sm:hidden block">
          <div className="container">
            <h2 className="text-white text-[24px]">OUR VALUES</h2>
            <p className="text-white text-[12px] mt-[20px]">At Secugame, we are fully devoted to our work, embrace change, and eagerly tackle new challenges. Together, we contribute to our client’s success, guided by shared values that define our highly productive team and reflect our identity.</p>
            <div className="w-full flex gap-x-12 mt-10 pt-10 border-t-[1px] border-primary-500">
              <div className="w-1/2">
                <img className="lg:h-[65px] h-[45px]" src={SecuTrust} alt="SecuTrust" />
                <p className="mt-[24px] font-semibold lg:text-[20px] text-[12px] text-primary-500">TRUST</p>
                <p className="text-white lg:text-[18px] text-[12px] mt-[7px]">We uphold honesty, keep our word, and take collective responsibility, fostering trust among ourselves and our clients.</p>
              </div>
              <div className="w-1/2">
                <img className="lg:h-[65px] h-[45px]" src={SecuCollaboration} alt="SecuCollaboration" />
                <p className="mt-[24px] font-semibold lg:text-[20px] text-[12px] text-primary-500">COLLABORATION</p>
                <p className="text-white lg:text-[18px] text-[12px] mt-[7px]">Collaboration defines us; we work together, support each other, and serve our clients and society with a shared sense of purpose.</p>
              </div>
            </div>
            <div className="w-full flex gap-x-12 mt-[69px]">
              <div className="w-1/2">
                <img className="lg:h-[65px] h-[45px]" src={SecuOpeness} alt="SecuOpeness" />
                <p className="mt-[24px] font-semibold lg:text-[20px] text-[12px] text-primary-500">OPENESS</p>
                <p className="text-white lg:text-[18px] text-[12px] mt-[7px]">Open dialogue fuels our growth; we encourage transparency, embrace learning from everyone, and value respectful communication at all levels.</p>
              </div>
              <div className="w-1/2">
                <img className="lg:h-[65px] h-[45px]" src={SecuExcellence} alt="SecuTrust" />
                <p className="mt-[24px] font-semibold lg:text-[20px] text-[12px] text-primary-500">EXCELLENCE</p>
                <p className="text-white lg:text-[18px] text-[12px] mt-[7px]">Excellence drives us; we prioritize optimal solutions, client relationships, and deliver high-quality results through experience and empathy.</p>
              </div>
            </div>
            <div className="w-full flex gap-x-12 mt-[69px]">
              <div className="w-1/2">
                <img className="lg:h-[65px] h-[45px]" src={SecuInnovation} alt="SecuInnovation" />
                <p className="mt-[24px] font-semibold lg:text-[20px] text-[12px] text-primary-500">INNOVATION</p>
                <p className="text-white lg:text-[18px] text-[12px] mt-[7px]">Curiosity fuels our progress; we explore new horizons, foster a thirst for knowledge, and pursue innovation with passion and dedication.</p>
              </div>
            </div>
            <div className="our-value lg:h-[596px] h-[396px] mt-[70px] pl-[41px] pb-[56px] flex items-end">
              <SecuButton
                state={"PRIMARY"}
                text={"Speak with Us"}
                type={"button"}
                onClick={() => navigate("/contact")}
              />
            </div>
          </div>
        </section>
        <section className="lg:mt-[353px] sm:mt-[153px] mt-[53px] protect-two lg:h-[454px] sm:h-[254px] h-[204px] flex flex-col justify-center items-center text-center">
          <div className="sm:max-w-[982px] max-w-[382px]">
            <div className="w-full">
              <h1 className="lg:text-[52px] sm:text-[42px] text-[20px] mt-2 text-white">Protect your Digital Assets Safely</h1>
              <p className="lg:text-[20px] sm:text-[16px] text-[12px] mt-2 text-white font-light">Let’s help you build a cyber defence system that brings money in and keeps hackers out forever. Contact us today to discuss your IT security needs and partnership opportunities.</p>
              <div className="mt-8 flex justify-center">
                <SecuButton
                  state={"PRIMARY"}
                  text={"Learn more"}
                  type={"button"}
                  onClick={() => navigate("/industries")}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Main>
  );
};

export default About;