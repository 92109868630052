import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import Main from "../../layout/Main";
import "./styles.scss";
import { Collapse } from 'antd';
import SecuArrows from "../../assets/imgs/secu-arrows.png";
import { CV, DS, GBM, HEALTH, IUM_ONE, IUM_TWO, RISS } from "../../utils/constants";
import { useInView } from "react-intersection-observer";

const Industries = () => {
  const [active, setActive] = useState("bc");
  const [activeFirst, setActiveFirst] = useState(['1']);
  const [activeSecond, setActiveSecond] = useState(['3']);
  const [ref, inView] = useInView({
    threshold: 0.1
  });

  const [ref2, inView2] = useInView({
    threshold: 0.1
  });

  const [ref3, inView3] = useInView({
    threshold: 0.1
  });

  const [ref4, inView4] = useInView({
    threshold: 0.1
  });

  const [ref5, inView5] = useInView({
    threshold: 0.1
  });

  const [ref6, inView6] = useInView({
    threshold: 0.1
  });


  const activeTab = () => {
    if (inView) {
      return "bc";
    } else if (inView2 && !inView) {
      return "gb";
    } else if (inView3 && !inView2 && !inView) {
      return "hc";
    } else if (inView4 && !inView3 && !inView2 && !inView) {
      return "ds";
    } else if (inView5 && !inView4 && !inView3 && !inView2 && !inView) {
      return "iu";
    } else if (inView6 && !inView5 && !inView4 && !inView3 && !inView2 && !inView) {
      return "cs";
    }
  };
  const handleClickScroll = (e, id) => {
    e.preventDefault();
    const element = document.getElementById(id);
    if (element) {
      setActive(id);
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleFirstChange = (x) => {
    setActiveFirst(x);
  }

  const handleSecondChange = (x) => {
    setActiveSecond(x);
  }
  const items = [
    {
      key: '1',
      label: <div className="w-full flex">
        <div className="w-11/12">
          <p className="lg:text-[20px] sm:text-[16px] text-[12px] font-bold">Solutions for Industry 4.0 and IIoT</p>
          <p className="lg:text-[14px] text-[10px]">EDGE, MONITOR, EID PKI SUITE, CRYPTOSERVICES, SINA WORKSTATION S, STASHCAT</p>
        </div>
        <div className="w-1/12">
          <div className="ml-auto">
            {activeFirst?.includes('1') ? <svg className="secu-svg" xmlns="http://www.w3.org/2000/svg" width="46.5" height="46.5" viewBox="0 0 46.5 46.5">
              <g id="Component_111_1" data-name="Component 111 – 1" transform="translate(0.75 0.75)">
                <circle id="Ellipse_169" data-name="Ellipse 169" cx="22.5" cy="22.5" r="22.5" fill="none" stroke="#e81e49" strokeWidth="1.5" />
                <g id="Group_167718" data-name="Group 167718" transform="translate(-382.866 -1516.601)">
                  <path id="Path_118086" data-name="Path 118086" d="M6434.177,801.021h12.573l13.557-13.558" transform="translate(-6048.717 747.136)" fill="none" stroke="#e81e49" strokeLinecap="round" strokeWidth="1.5" />
                  <path id="Path_118100" data-name="Path 118100" d="M163.1,18.963h11.559V30.136" transform="translate(236.992 1515.636)" fill="none" stroke="#e81e49" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Path_118101" data-name="Path 118101" d="M163.1,18.963h11.559V30.136" transform="translate(241.546 1511.082)" fill="none" stroke="#e81e49" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                </g>
              </g>
            </svg> : <svg className="secu-svg" xmlns="http://www.w3.org/2000/svg" width="46.5" height="46.5" viewBox="0 0 46.5 46.5">
              <g id="Component_98_2" data-name="Component 98 – 2" transform="translate(0.75 0.75)">
                <circle id="Ellipse_169" data-name="Ellipse 169" cx="22.5" cy="22.5" r="22.5" fill="none" stroke="#e6204b" strokeWidth="1.5" />
                <g id="Group_167718" data-name="Group 167718" transform="translate(2.594 13.444)">
                  <path id="Path_118086" data-name="Path 118086" d="M6434.177,787.463h12.573l13.557,13.558" transform="translate(-6434.177 -787.463)" fill="none" stroke="#e6204b" strokeLinecap="round" strokeWidth="1.5" />
                  <path id="Path_118100" data-name="Path 118100" d="M163.1,30.136h11.559V18.963" transform="translate(-148.468 -16.578)" fill="none" stroke="#e6204b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Path_118101" data-name="Path 118101" d="M163.1,30.136h11.559V18.963" transform="translate(-143.914 -12.024)" fill="none" stroke="#e6204b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                </g>
              </g>
            </svg>
            }
          </div>
        </div>
      </div>,
      showArrow: false,
      children: <div className="grid sm:grid-cols-3 grid-cols-2 gap-4">
        {IUM_ONE.map(i => <div key={i?.title} className="sm:h-[245px] h-[205px] sm:p-[21px] p-[16px] flex flex-col justify-between red-card">
          <div className="w-full flex">
            <p className="text-[12px] first-p w-[7/12]">{i?.title}</p>
            <div className="ml-auto">
              <svg className="secu-svg" xmlns="http://www.w3.org/2000/svg" width="46.5" height="46.5" viewBox="0 0 46.5 46.5">
                <g id="Component_111_1" data-name="Component 111 – 1" transform="translate(0.75 0.75)">
                  <circle id="Ellipse_169" data-name="Ellipse 169" cx="22.5" cy="22.5" r="22.5" fill="none" stroke="#ffffff" strokeWidth="1.5" />
                  <g id="Group_167718" data-name="Group 167718" transform="translate(-382.866 -1516.601)">
                    <path id="Path_118086" data-name="Path 118086" d="M6434.177,801.021h12.573l13.557-13.558" transform="translate(-6048.717 747.136)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeWidth="1.5" />
                    <path id="Path_118100" data-name="Path 118100" d="M163.1,18.963h11.559V30.136" transform="translate(236.992 1515.636)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <path id="Path_118101" data-name="Path 118101" d="M163.1,18.963h11.559V30.136" transform="translate(241.546 1511.082)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div className="w-full">
            <p className="sm:text-[16px] text-[12px] middle-p mb-2">{i?.sub}</p>
            <p className="sm:text-[14px] text-[12px] second-p">{i?.desc}</p>
          </div>
        </div>)}
      </div>,
    },
    {
      key: '2',
      label: <div className="w-full flex">
        <div className="w-11/12">
          <p className="lg:text-[20px] sm:text-[16px] text-[12px] font-bold">Consulting for Industry 4.0 and IIoT</p>
          <p className="lg:text-[14px] text-[10px]">INCIDENT HOTLINE, AWARENESS TRAINING, BCMS, ISMS, CYBERSECURITY SITUATION, AUDITS & ANALY…….</p>
        </div>
        <div className="w-1/12">
          <div className="ml-auto">
            {activeFirst?.includes('2') ? <svg className="secu-svg" xmlns="http://www.w3.org/2000/svg" width="46.5" height="46.5" viewBox="0 0 46.5 46.5">
              <g id="Component_111_1" data-name="Component 111 – 1" transform="translate(0.75 0.75)">
                <circle id="Ellipse_169" data-name="Ellipse 169" cx="22.5" cy="22.5" r="22.5" fill="none" stroke="#e81e49" strokeWidth="1.5" />
                <g id="Group_167718" data-name="Group 167718" transform="translate(-382.866 -1516.601)">
                  <path id="Path_118086" data-name="Path 118086" d="M6434.177,801.021h12.573l13.557-13.558" transform="translate(-6048.717 747.136)" fill="none" stroke="#e81e49" strokeLinecap="round" strokeWidth="1.5" />
                  <path id="Path_118100" data-name="Path 118100" d="M163.1,18.963h11.559V30.136" transform="translate(236.992 1515.636)" fill="none" stroke="#e81e49" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Path_118101" data-name="Path 118101" d="M163.1,18.963h11.559V30.136" transform="translate(241.546 1511.082)" fill="none" stroke="#e81e49" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                </g>
              </g>
            </svg> : <svg className="secu-svg" xmlns="http://www.w3.org/2000/svg" width="46.5" height="46.5" viewBox="0 0 46.5 46.5">
              <g id="Component_98_2" data-name="Component 98 – 2" transform="translate(0.75 0.75)">
                <circle id="Ellipse_169" data-name="Ellipse 169" cx="22.5" cy="22.5" r="22.5" fill="none" stroke="#e6204b" strokeWidth="1.5" />
                <g id="Group_167718" data-name="Group 167718" transform="translate(2.594 13.444)">
                  <path id="Path_118086" data-name="Path 118086" d="M6434.177,787.463h12.573l13.557,13.558" transform="translate(-6434.177 -787.463)" fill="none" stroke="#e6204b" strokeLinecap="round" strokeWidth="1.5" />
                  <path id="Path_118100" data-name="Path 118100" d="M163.1,30.136h11.559V18.963" transform="translate(-148.468 -16.578)" fill="none" stroke="#e6204b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Path_118101" data-name="Path 118101" d="M163.1,30.136h11.559V18.963" transform="translate(-143.914 -12.024)" fill="none" stroke="#e6204b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                </g>
              </g>
            </svg>
            }
          </div>
        </div>
      </div>,
      showArrow: false,
      children: <div className="grid sm:grid-cols-3 grid-cols-2 gap-4">
        {IUM_TWO.map(i => <div key={i?.title} className="sm:h-[245px] h-[205px] sm:p-[21px] p-[16px] flex flex-col justify-between red-card">
          <div className="w-full flex">
            <p className="text-[12px] first-p w-[7/12]">{i?.title}</p>
            <div className="ml-auto">
              <svg className="secu-svg" xmlns="http://www.w3.org/2000/svg" width="46.5" height="46.5" viewBox="0 0 46.5 46.5">
                <g id="Component_111_1" data-name="Component 111 – 1" transform="translate(0.75 0.75)">
                  <circle id="Ellipse_169" data-name="Ellipse 169" cx="22.5" cy="22.5" r="22.5" fill="none" stroke="#ffffff" strokeWidth="1.5" />
                  <g id="Group_167718" data-name="Group 167718" transform="translate(-382.866 -1516.601)">
                    <path id="Path_118086" data-name="Path 118086" d="M6434.177,801.021h12.573l13.557-13.558" transform="translate(-6048.717 747.136)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeWidth="1.5" />
                    <path id="Path_118100" data-name="Path 118100" d="M163.1,18.963h11.559V30.136" transform="translate(236.992 1515.636)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <path id="Path_118101" data-name="Path 118101" d="M163.1,18.963h11.559V30.136" transform="translate(241.546 1511.082)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div className="w-full">
            <p className="sm:text-[16px] text-[12px] middle-p mb-2">{i?.sub}</p>
            <p className="sm:text-[14px] text-[12px] second-p">{i?.desc}</p>
          </div>
        </div>)}
      </div>,
    }
  ];

  const itemsTwo = [
    {
      key: '3',
      label: <div className="w-full flex">
        <div className="w-11/12">
          <p className="lg:text-[20px] text-[16px] font-bold">Cloud Variants</p>
          <p className="lg:text-[14px] text-[10px]">PUBLIC CLOUD, PRIVATE CLOUD, HYBRID CLOUD, MULTI CLOUD</p>
        </div>
        <div className="w-1/12">
          <div className="ml-auto">
            {activeSecond?.includes("3") ? <svg className="secu-svg" xmlns="http://www.w3.org/2000/svg" width="46.5" height="46.5" viewBox="0 0 46.5 46.5">
              <g id="Component_111_1" data-name="Component 111 – 1" transform="translate(0.75 0.75)">
                <circle id="Ellipse_169" data-name="Ellipse 169" cx="22.5" cy="22.5" r="22.5" fill="none" stroke="#e81e49" strokeWidth="1.5" />
                <g id="Group_167718" data-name="Group 167718" transform="translate(-382.866 -1516.601)">
                  <path id="Path_118086" data-name="Path 118086" d="M6434.177,801.021h12.573l13.557-13.558" transform="translate(-6048.717 747.136)" fill="none" stroke="#e81e49" strokeLinecap="round" strokeWidth="1.5" />
                  <path id="Path_118100" data-name="Path 118100" d="M163.1,18.963h11.559V30.136" transform="translate(236.992 1515.636)" fill="none" stroke="#e81e49" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Path_118101" data-name="Path 118101" d="M163.1,18.963h11.559V30.136" transform="translate(241.546 1511.082)" fill="none" stroke="#e81e49" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                </g>
              </g>
            </svg> : <svg className="secu-svg" xmlns="http://www.w3.org/2000/svg" width="46.5" height="46.5" viewBox="0 0 46.5 46.5">
              <g id="Component_98_2" data-name="Component 98 – 2" transform="translate(0.75 0.75)">
                <circle id="Ellipse_169" data-name="Ellipse 169" cx="22.5" cy="22.5" r="22.5" fill="none" stroke="#e6204b" strokeWidth="1.5" />
                <g id="Group_167718" data-name="Group 167718" transform="translate(2.594 13.444)">
                  <path id="Path_118086" data-name="Path 118086" d="M6434.177,787.463h12.573l13.557,13.558" transform="translate(-6434.177 -787.463)" fill="none" stroke="#e6204b" strokeLinecap="round" strokeWidth="1.5" />
                  <path id="Path_118100" data-name="Path 118100" d="M163.1,30.136h11.559V18.963" transform="translate(-148.468 -16.578)" fill="none" stroke="#e6204b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  <path id="Path_118101" data-name="Path 118101" d="M163.1,30.136h11.559V18.963" transform="translate(-143.914 -12.024)" fill="none" stroke="#e6204b" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                </g>
              </g>
            </svg>
            }
          </div>
        </div>
      </div>,
      showArrow: false,
      children: <div className="grid sm:grid-cols-3 grid-cols-2 gap-4">
        {CV.map(c => <div key={c?.title} className="sm:h-[245px] h-[205px] sm:p-[21px] p-[16px] flex flex-col justify-between red-card">
          <div className="w-full flex">
            <p className="text-[12px] first-p w-[7/12]">{c?.title}</p>
            <div className="ml-auto">
              <svg className="secu-svg" xmlns="http://www.w3.org/2000/svg" width="46.5" height="46.5" viewBox="0 0 46.5 46.5">
                <g id="Component_111_1" data-name="Component 111 – 1" transform="translate(0.75 0.75)">
                  <circle id="Ellipse_169" data-name="Ellipse 169" cx="22.5" cy="22.5" r="22.5" fill="none" stroke="#ffffff" strokeWidth="1.5" />
                  <g id="Group_167718" data-name="Group 167718" transform="translate(-382.866 -1516.601)">
                    <path id="Path_118086" data-name="Path 118086" d="M6434.177,801.021h12.573l13.557-13.558" transform="translate(-6048.717 747.136)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeWidth="1.5" />
                    <path id="Path_118100" data-name="Path 118100" d="M163.1,18.963h11.559V30.136" transform="translate(236.992 1515.636)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <path id="Path_118101" data-name="Path 118101" d="M163.1,18.963h11.559V30.136" transform="translate(241.546 1511.082)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div className="w-full">
            <p className="sm:text-[14px] text-[12px] second-p">{c?.desc}</p>
          </div>
        </div>)}
      </div>,
    }
  ];

  return (
    <Main>
      <Helmet>
        <title>Industries | secugame</title>
        <meta name="keywords" content="New Era" />
      </Helmet>
      <div className={"min-h-screen h-full overflow-x-hidden"}>
        <section className="w-full mt-[100px] relative max-w-[1440px] mx-auto">
          <div className="w-[460px] fixed top-[13.7rem] xl:block hidden">
            <div className="border-b border-primary-500 w-full flex pl-[79px]">
              <p className="text-[52px] text-white leading-[1]">Industries</p>
            </div>
            <div className="pl-[79px] mt-[40px]">
              <div className="max-w-[298px] pl-[30px]">
                <p className={`text-[20px] mb-[25px] menu-item ${(active === 'bc' && activeTab() === 'bc') || activeTab() === 'bc' ? 'active' : ''}`} onClick={(e) => handleClickScroll(e, "bc")}>
                  <img
                    className={"h-4 absolute left-[-30px] top-[8px]"}
                    src={SecuArrows}
                    alt={"Logo"}
                  />
                  Border Control, Police & Security Agencies
                </p>
                <p className={`text-[20px] mb-[25px] menu-item ${(active === 'gb' && activeTab() === 'gb') || activeTab() === 'gb' ? 'active' : ''}`} onClick={(e) => handleClickScroll(e, "gb")}>
                  <img
                    className={"h-4 absolute left-[-30px] top-[8px]"}
                    src={SecuArrows}
                    alt={"Logo"}
                  />
                  Government Bodies & Ministries
                </p>
                <p className={`text-[20px] mb-[25px] menu-item ${(active === 'hc' && activeTab() === 'hc') || activeTab() === 'hc' ? 'active' : ''}`} onClick={(e) => handleClickScroll(e, "hc")}>
                  <img
                    className={"h-4 absolute left-[-30px] top-[8px]"}
                    src={SecuArrows}
                    alt={"Logo"}
                  />
                  Healthcare</p>
                <p className={`text-[20px] mb-[25px] menu-item ${(active === 'ds' && activeTab() === 'ds') || activeTab() === 'ds' ? 'active' : ''}`} onClick={(e) => handleClickScroll(e, "ds")}>
                  <img
                    className={"h-4 absolute left-[-30px] top-[8px]"}
                    src={SecuArrows}
                    alt={"Logo"}
                  />
                  Defense & Space
                </p>
                <p className={`text-[20px] mb-[25px] menu-item ${(active === 'iu' && activeTab() === 'iu') || activeTab() === 'iu' ? 'active' : ''}`} onClick={(e) => handleClickScroll(e, "iu")}>
                  <img
                    className={"h-4 absolute left-[-30px] top-[8px]"}
                    src={SecuArrows}
                    alt={"Logo"}
                  />
                  Industry, Utilities & Mobility
                </p>
                <p className={`text-[20px] mb-[25px] menu-item ${(active === 'cs' && activeTab() === 'cs') || activeTab() === 'cs' ? 'active' : ''}`} onClick={(e) => handleClickScroll(e, "cs")}>
                  <img
                    className={"h-4 absolute left-[-30px] top-[8px]"}
                    src={SecuArrows}
                    alt={"Logo"}
                  />
                  Cloud Solutions
                </p>
              </div>
            </div>
          </div>
          <div className="ml-auto sm:max-w-[956px] max-w-[390px] xl:hidden">
            <div className="border-b border-primary-500 w-full flex xl:pl-[79px] xl:mb-0 mb-10">
              <p className="xl:text-[52px] sm:text-[42px] text-[22px] text-white sm:leading-[1]">Industries</p>
            </div>
          </div>
          <div className="ml-auto xl:max-w-[956px]">
            {/* Border Control, Police & Security Agencies */}
            <div ref={ref} id="bc" className="border-control w-full xl:h-[288px] sm:h-[308px] h-[258px] px-[32px] py-[30px] flex items-end">
              <p className="lg:text-[32px] text-[22px] font-semibold text-white">Border Control, Police & Security Agencies</p>
            </div>
            <div className="mt-[32px] sm:pl-[10px] sm:pr-[64px] pl-[24px] pr-[24px]">
              <p className="text-white font-semibold lg:text-[24px] sm:text-[20px] text-[16px]">Security at its best</p>
              <p className="text-white lg:text-[20px] sm:text-[16px] text-[12px] mt-[10px]">Empower national security forces with top-level encryption, surveillance, and security for optimal operations in National, international, and in-missions.</p>
              <p className="text-white lg:text-[20px] sm:text-[16px] text-[12px] mt-[25px]">We support security authorities in protecting society and the state against crime and terrorism, as well as civil defense and emergency services organizations that provide technical and humanitarian assistance.</p>
              <p className="text-primary-500 font-semibold lg:text-[24px] sm:text-[20px] text-[16px] mt-[25px]">Reliable IT security solutions</p>
              <p className="text-white lg:text-[20px] sm:text-[16px] text-[12px] mt-[25px]">Public authorities and organisations tasked with ensuring safety & security put extremely high standards on their IT and communication infrastructures. The capturing of data must be of high quality, while processing and transmission must be handled confidentially and securely. And it is also essential for data and information to be made available quickly and flexibly. Regardless of when and where – security organisations must be able to depend on their IT and communication systems around the clock. To this end, secugame delivers customized, holistic and intelligent IT security solutions – both nationally and internationally.</p>
            </div>
            <div className="xl:px-0 px-[20px] mt-[64px] grid lg:grid-cols-10 grid-cols-12 gap-4">
              {RISS.map(r => <div key={r.name} className="lg:col-span-3 sm:col-span-4 col-span-6 sm:h-[245px] h-[205px] sm:p-[21px] p-[16px] flex flex-col justify-between red-card">
                <p className="sm:text-[14px] text-[12px] first-p">{r.name}</p>
                <div className="flex justify-between items-end">
                  <p className="sm:text-[14px] text-[12px] w-8/12 second-p">{r.desc}</p>
                  <svg className="secu-svg" xmlns="http://www.w3.org/2000/svg" width="46.5" height="46.5" viewBox="0 0 46.5 46.5">
                    <g id="Component_111_1" data-name="Component 111 – 1" transform="translate(0.75 0.75)">
                      <circle id="Ellipse_169" data-name="Ellipse 169" cx="22.5" cy="22.5" r="22.5" fill="none" stroke="#ffffff" strokeWidth="1.5" />
                      <g id="Group_167718" data-name="Group 167718" transform="translate(-382.866 -1516.601)">
                        <path id="Path_118086" data-name="Path 118086" d="M6434.177,801.021h12.573l13.557-13.558" transform="translate(-6048.717 747.136)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeWidth="1.5" />
                        <path id="Path_118100" data-name="Path 118100" d="M163.1,18.963h11.559V30.136" transform="translate(236.992 1515.636)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Path_118101" data-name="Path 118101" d="M163.1,18.963h11.559V30.136" transform="translate(241.546 1511.082)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>)}
            </div>

            {/* Government Bodies & Ministries */}
            <div ref={ref2} id="gb" className="govt w-full xl:h-[288px] sm:h-[308px] h-[258px] px-[32px] py-[30px] flex items-end mt-[100px]">
              <p className="lg:text-[32px] text-[22px] font-semibold text-white">Government Bodies & Ministries</p>
            </div>
            <div className="mt-[32px] pl-[10px] pr-[64px]">
              <p className="text-white lg:text-[20px] sm:text-[16px] text-[12px] mt-[10px]">Public safety just got smarter. We make daily tasks simpler, safer, and faster for law enforcement agencies with intelligent tools and expert advice.</p>
              <p className="text-white lg:text-[20px] sm:text-[16px] text-[12px] mt-[25px]">Modern administration is increasingly working digitally. This requires efficient and secure solutions to meet the requirements of a public authority. We are familiar with the high demands placed on IT infrastructures and IT security and support the federal, state and local governments on their way to a comprehensive digital and securely networked administration.</p>
              <p className="text-primary-500 font-semibold lg:text-[24px] sm:text-[20px] text-[16px] mt-[25px]">Successful digitization in the day-to-day work of public authorities</p>
              <p className="text-white lg:text-[20px] sm:text-[16px] text-[12px] mt-[25px]">For the federal, state and local governments, digitization represents an opportunity, but it also poses many challenges. IT networks must be powerful and secured against cyberattacks. Administrations work with sensitive data, for example from citizens, which requires special protection, and legal requirements and regulations must be implemented. For successful digitization, the entire IT infrastructure should be considered, from access to the citizen portal to data exchange between government employees.</p>
            </div>
            <div className="xl:px-0 px-[20px] mt-[64px] grid lg:grid-cols-10 grid-cols-12 gap-4">
              {GBM.map(g => <div key={g.name} className="lg:col-span-3 sm:col-span-4 col-span-6 sm:h-[245px] h-[205px] sm:p-[21px] p-[16px] flex flex-col justify-between red-card">
                <p className="sm:text-[14px] text-[12px] first-p">{g.name}</p>
                <div className="flex justify-between items-end">
                  <p className="sm:text-[14px] text-[12px] w-8/12 second-p">{g.desc}</p>
                  <svg className="secu-svg" xmlns="http://www.w3.org/2000/svg" width="46.5" height="46.5" viewBox="0 0 46.5 46.5">
                    <g id="Component_111_1" data-name="Component 111 – 1" transform="translate(0.75 0.75)">
                      <circle id="Ellipse_169" data-name="Ellipse 169" cx="22.5" cy="22.5" r="22.5" fill="none" stroke="#ffffff" strokeWidth="1.5" />
                      <g id="Group_167718" data-name="Group 167718" transform="translate(-382.866 -1516.601)">
                        <path id="Path_118086" data-name="Path 118086" d="M6434.177,801.021h12.573l13.557-13.558" transform="translate(-6048.717 747.136)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeWidth="1.5" />
                        <path id="Path_118100" data-name="Path 118100" d="M163.1,18.963h11.559V30.136" transform="translate(236.992 1515.636)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Path_118101" data-name="Path 118101" d="M163.1,18.963h11.559V30.136" transform="translate(241.546 1511.082)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      </g>
                    </g>
                  </svg>
                </div>
              </div>)}
            </div>

            {/* Healthcare */}
            <div ref={ref3} id="hc" className="health w-full xl:h-[288px] sm:h-[308px] h-[258px] px-[32px] py-[30px] flex items-end mt-[100px]">
              <p className="lg:text-[32px] text-[22px] font-semibold text-white">Healthcare</p>
            </div>
            <div className="mt-[32px] pl-[10px] pr-[64px]">
              <p className="text-white font-semibold lg:text-[24px] sm:text-[20px] text-[16px] mt-[25px]">Digitalization in healthcare</p>
              <p className="text-white lg:text-[20px] sm:text-[16px] text-[12px] mt-[25px]">Make every life count with digital solutions that secure medical data and equipment and boost telemedicine across the healthcare chain.</p>
              <p className="text-white lg:text-[20px] sm:text-[16px] text-[12px] mt-[25px]">Our IT security solutions are designed for the future. We offer technologies that reliably protect confidential medical data, its transmission and environment. Enabling secure communications and comprehensive handling of healthcare data, ensuring seamless operations within the healthcare chain</p>
            </div>
            <div className="xl:px-0 px-[20px] mt-[64px] grid lg:grid-cols-10 grid-cols-12 gap-4">
              {HEALTH.map(h => <div key={h.title} className="lg:col-span-3 sm:col-span-4 col-span-6 sm:h-[245px] h-[205px] sm:p-[21px] p-[16px] flex flex-col justify-between red-card">
                <div className="w-full flex">
                  <p className="text-[12px] first-p w-[7/12]">{h.title}</p>
                  <svg className="secu-svg" xmlns="http://www.w3.org/2000/svg" width="46.5" height="46.5" viewBox="0 0 46.5 46.5">
                    <g id="Component_111_1" data-name="Component 111 – 1" transform="translate(0.75 0.75)">
                      <circle id="Ellipse_169" data-name="Ellipse 169" cx="22.5" cy="22.5" r="22.5" fill="none" stroke="#ffffff" strokeWidth="1.5" />
                      <g id="Group_167718" data-name="Group 167718" transform="translate(-382.866 -1516.601)">
                        <path id="Path_118086" data-name="Path 118086" d="M6434.177,801.021h12.573l13.557-13.558" transform="translate(-6048.717 747.136)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeWidth="1.5" />
                        <path id="Path_118100" data-name="Path 118100" d="M163.1,18.963h11.559V30.136" transform="translate(236.992 1515.636)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        <path id="Path_118101" data-name="Path 118101" d="M163.1,18.963h11.559V30.136" transform="translate(241.546 1511.082)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="w-full">
                  <p className="sm:text-[16px] text-[12px] middle-p mb-2">{h.sub}</p>
                  <p className="sm:text-[14px] text-[12px] second-p">{h.desc}</p>
                </div>
              </div>)}
            </div>

            {/* Defense & Space */}
            <div ref={ref4} id="ds" className="defense w-full xl:h-[288px] sm:h-[308px] h-[258px] px-[32px] py-[30px] flex items-end mt-[100px]">
              <p className="lg:text-[32px] text-[22px] font-semibold text-white">Defense & Space</p>
            </div>
            <div className="mt-[32px] pl-[10px] pr-[64px]">
              <p className="text-white font-semibold lg:text-[24px] sm:text-[20px] text-[16px] mt-[25px]">Armed Forces, Agencies & Space Organizations</p>
              <p className="text-white lg:text-[20px] sm:text-[16px] text-[12px] mt-[25px]">Providing top-level encryption for national and international data transmission, ensuring utmost security, even in classified missions</p>
              <p className="text-white lg:text-[20px] sm:text-[16px] text-[12px] mt-[25px]">Digitization has been influencing armed forces for quite some time already and is now one of the driving forces behind ensuring their ability to act in all areas in the long term. This requires efficient and proven solutions that guarantee the availability, integrity and confidentiality of classified data up to SECRET.</p>
              <p className="text-white lg:text-[20px] sm:text-[16px] text-[12px] mt-[25px]">We offer solutions for</p>
              <ul className="list-disc pl-[20px] marker:text-primary-500 mt-[12px]">
                <li className="text-white lg:text-[20px] sm:text-[16px] text-[12px]">Protection against cyber attacks on IT infrastructures as well as internal and external communications of public authorities, armed forces and the industry</li>
                <li className="text-white lg:text-[20px] sm:text-[16px] text-[12px]">Secure mobile and stationary workstations for government employees and members of the armed forces</li>
                <li className="text-white lg:text-[20px] sm:text-[16px] text-[12px]">Secure, confidential, non-manipulable work with classified information up to SECRET level</li>
                <li className="text-white lg:text-[20px] sm:text-[16px] text-[12px]">Security technologies from the areas of network monitoring (cyber defense), digital identities and biometric security solutions</li>
              </ul>
            </div>
            <div className="xl:px-0 px-[20px] mt-[64px] grid lg:grid-cols-10 grid-cols-12 gap-4">
              {DS.map(d => <div key={d?.title} className="lg:col-span-3 sm:col-span-4 col-span-6 sm:h-[245px] h-[205px] sm:p-[21px] p-[16px] flex flex-col justify-between red-card">
                <div className="w-full flex">
                  <p className="text-[12px] first-p w-[7/12]">{d?.title}</p>
                  <div className="ml-auto">
                    <svg className="secu-svg" xmlns="http://www.w3.org/2000/svg" width="46.5" height="46.5" viewBox="0 0 46.5 46.5">
                      <g id="Component_111_1" data-name="Component 111 – 1" transform="translate(0.75 0.75)">
                        <circle id="Ellipse_169" data-name="Ellipse 169" cx="22.5" cy="22.5" r="22.5" fill="none" stroke="#ffffff" strokeWidth="1.5" />
                        <g id="Group_167718" data-name="Group 167718" transform="translate(-382.866 -1516.601)">
                          <path id="Path_118086" data-name="Path 118086" d="M6434.177,801.021h12.573l13.557-13.558" transform="translate(-6048.717 747.136)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeWidth="1.5" />
                          <path id="Path_118100" data-name="Path 118100" d="M163.1,18.963h11.559V30.136" transform="translate(236.992 1515.636)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                          <path id="Path_118101" data-name="Path 118101" d="M163.1,18.963h11.559V30.136" transform="translate(241.546 1511.082)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        </g>
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="w-full">
                  <p className="sm:text-[16px] text-[12px] middle-p mb-2">{d.sub}</p>
                  <p className="sm:text-[14px] text-[12px] second-p">{d.desc}</p>
                </div>
              </div>)}
            </div>

            {/* Industry, Utilities & Mobility */}
            <div ref={ref5} id="iu" className="mobilty w-full xl:h-[288px] sm:h-[308px] h-[258px] px-[32px] py-[30px] flex items-end mt-[100px]">
              <p className="lg:text-[32px] text-[22px] font-semibold text-white">Industry, Utilities & Mobility</p>
            </div>
            <div className="mt-[32px] pl-[10px] pr-[64px]">
              <p className="text-white font-semibold lg:text-[24px] sm:text-[20px] text-[16px] mt-[25px]">IT security in the industry</p>
              <p className="text-white lg:text-[20px] sm:text-[16px] text-[12px] mt-[25px]">We protect our customers’ digital transformation through excellent consulting and effective security solutions.</p>
              <p className="text-white lg:text-[20px] sm:text-[16px] text-[12px] mt-[25px]">Offering tailored solutions for modern technological advancements, from connected vehicles to Industry 4.0, to propel industrial sectors forward.</p>
              <div className="grid grid-cols-2 gap-x-12 mt-[20px]">
                <div className="mt-[25px]">
                  <p className="font-semibold text-primary-500 lg:text-[20px] sm:text-[16px] text-[12px]">Industrial facilities</p>
                  <p className="mt-[8px] sm:text-[16px] text-[12px] text-white">Interconnection, securing and monitoring of production facilities</p>
                </div>
                <div className="mt-[25px]">
                  <p className="font-semibold text-primary-500 lg:text-[20px] sm:text-[16px] text-[12px]">Utilities</p>
                  <p className="mt-[8px] sm:text-[16px] text-[12px] text-white">Critical infrastructure and utility protection</p>
                </div>
                <div className="mt-[25px]">
                  <p className="font-semibold text-primary-500 lg:text-[20px] sm:text-[16px] text-[12px]">Digital infrastructures</p>
                  <p className="mt-[8px] sm:text-[16px] text-[12px] text-white">Securing network-based business models such as information technology and telecommunications</p>
                </div>
                <div className="mt-[25px]">
                  <p className="font-semibold text-primary-500 lg:text-[20px] sm:text-[16px] text-[12px]">Automotive & mobility</p>
                  <p className="mt-[8px] sm:text-[16px] text-[12px] text-white">Securing future-oriented and networked mobility and logistics concepts</p>
                </div>
              </div>
            </div>
            <div className="mt-10 lg:pr-[90px] first-accordion">
              <Collapse accordion items={items} defaultActiveKey={activeFirst} onChange={handleFirstChange} />
            </div>

            {/* Cloud Solutions */}
            <div ref={ref6} id="cs" className="cloud w-full xl:h-[288px] sm:h-[308px] h-[258px] px-[32px] py-[30px] flex items-end mt-[100px]">
              <p className="lg:text-[32px] text-[22px] font-semibold text-white">Cloud Solutions</p>
            </div>
            <div className="mt-[32px] pl-[10px] pr-[64px]">
              <p className="text-white font-semibold lg:text-[24px] sm:text-[20px] text-[16px] mt-[25px]">IT security in the industry</p>
              <p className="text-white lg:text-[20px] sm:text-[16px] text-[12px] mt-[25px]">Our sovereign cloud solutions. As private as required, as public as possible<br />Delivering secure, adaptable cloud solutions for both private and public digitization projects, available in flexible forms to suit varied needs.</p>
              <p className="text-white lg:text-[20px] sm:text-[16px] text-[12px] mt-[25px]">We design sovereign cloud solutions: on-premise, public or combined as a flexible hybrid cloud. For the cloud-native transition of public authorities and companies, we have combined the cloud computing standard OpenStack with the high-security technologies of SINA.</p>
              <p className="text-primary-500 font-semibold lg:text-[24px] sm:text-[20px] text-[16px] mt-[40px]">Cloud from A to Z</p>
              <p className="text-white lg:text-[20px] sm:text-[16px] text-[12px] mt-[25px]">As a full-service provider for cloud solutions, we relocate IT infrastructures securely and flexibly into the cloud. With our public, private, hybrid or multi-cloud as well as on-premise offerings, we deliver tailored solutions to meet your needs. 100% Made in Germany.<br /><br /> As a transition partner, we accompany you on your way into the cloud and support you in the implementation of your projects.<br /><br /> As a security partner of the Federal Republic of Germany, our partner Secrnet, have implement solutions that offer the highest data protection and custom-fit security - from GDPR to classified information level SECRET.</p>
            </div>
            <div className="mt-10 lg:pr-[90px] second-accordion">
              <Collapse accordion items={itemsTwo} defaultActiveKey={activeSecond} onChange={handleSecondChange} />
            </div>
          </div>
        </section>
      </div>
    </Main>
  );
};

export default Industries;