import SecuBorder from "../assets/imgs/secu-border.png";
import SecuGovtBodies from "../assets/imgs/secu-govt-bodies.png";
import SecuHealthcare from "../assets/imgs/secu-healthcare.png";
import SecuDefenseSpace from "../assets/imgs/secu-defense-space.png";
import SecusecuIndustryUtilities from "../assets/imgs/secu-industry-utilities.png";
import SecuCloudSolutions from "../assets/imgs/secu-cloud-solutions.png";

const INDUSTRIES = [
  {
    name: "Border Control, Police & Security Agencies",
    description:
      "Public safety just got smarter. We make daily tasks simpler, safer, and faster for law enforcement agencies with intelligent tools and expert advice.",
    image: SecuBorder,
  },
  {
    name: "Government Bodies & Ministries",
    description:
      "Streamline administration in a snap: We support government bodies at all levels to smoothly transition from paper and manual systems to secure, easy-to-use digital systems.",
    image: SecuGovtBodies,
  },
  {
    name: "Healthcare",
    description:
      "Make every life count with digital solutions that secure medical data and equipment, and boost telemedicine across the healthcare chain.",
    image: SecuHealthcare,
  },
  {
    name: "Defense & Space",
    description:
      "Security at its best: Empower national security forces with top-level encryption, surveillance, and security for optimal operations in National, international, and in-missions.",
    image: SecuDefenseSpace,
  },
  {
    name: "Industry, Utilities & Mobility",
    description:
      "From smart grids to connected stores, our tech protects against cyber threats, driving new business models and secure digital projects.",
    image: SecusecuIndustryUtilities,
  },
  {
    name: "Cloud Solutions",
    description:
      "Secure and adaptable cloud solutions for businesses and government projects, customisable for diverse needs, whether on-premise or public.",
    image: SecuCloudSolutions,
  },
];

const SERVICES = [
  {
    id: 1,
    name: "SINA WORKSTATION S",
    title: "All-in-one solution to process classified data",
    description:
      "The mobile solution for secure working from home and on the road",
  },
  {
    id: 2,
    name: "ARTIFICIAL INTELLIGENCE",
    title: "Security of and through Artificial Intelligence",
    description:
      "Our research and development includes not only the most powerful algorithms, but also a comprehensive safety consideration. Tailored to you and solution-oriented",
  },
  {
    id: 3,
    name: "AUDIT & ANALYSES",
    title: "Effective protection through safety analyses and audits",
    description:
      "Audit preparation and support as part of certification audits to meet legal and regulatory requirements.",
  },
  {
    id: 4,
    name: "AWARENESS TRAINING",
    title: "Raising employee awareness as a preventive foundation",
    description:
      "The goal of our awareness program: employees recognize the benefits of cybersecurity and change their behavior on their own.",
  },
  {
    id: 5,
    name: "BIOMETRICS",
    title: "Consulting on our biometrics and eID solutions",
    description:
      "For us, biometrics is more than a trendy topic: For more than 20 years, we have been successfully implementing key biometric projects throughout Europe.",
  },
  {
    id: 6,
    name: "BIOMIDDLE",
    title: "Independent middleware",
    description:
      "The standard-oriented solution for all conceivable biometrics and eID applications",
  },
  {
    id: 7,
    name: "BOCCA",
    title: "Digital ID-Tool",
    description: "Digitally bundle all information for a quick ID check",
  },
  {
    id: 8,
    name: "BORDER CONTROL",
    title: "Consulting on border control processes",
    description:
      "For secure, efficient and future-proof air, land and sea borders",
  },
  {
    id: 9,
    name: "BUSINESS COMMUNITY MANAGEMENT SYSTEM",
    title: "Business Continuity Management System (BCMS)",
    description:
      "Disruptions and crises can hit a company unannounced at any time. The best protection lies in prevention. Secure your business operations with emergency plans.",
  },
  {
    id: 10,
    name: "CRYPTOSERVICES",
    title: "Secure key management and crypto services",
    description: "",
  },
  {
    id: 11,
    name: "CYBERSERCURITY SITUATION",
    title: "Cybersecurity Situation",
    description:
      "Disruptions and crises can hit a company unannounced at any time. The best protection lies in prevention. Secure your business operations with emergency plans.",
  },
  {
    id: 12,
    name: "DATA PROTECTION MANAGEMENT",
    title: "Effective support for data protection",
    description: "",
  },
  {
    id: 13,
    name: "EASYGATE",
    title: "Automated border control system",
    description:
      "Automated border control that meets the highest security standards",
  },
  {
    id: 14,
    name: "EASYKIOSK",
    title: "Self-service kiosk system",
    description:
      "The self-service for shorter queues at border crossing points",
  },
  {
    id: 15,
    name: "EASY TOWER",
    title: "Automated biometric capture",
    description:
      "Highest quality facial images for all stationary applications",
  },
  {
    id: 16,
    name: "EES",
    title: "Partners beyond EES",
    description:
      "For safe, efficient and forward-looking air, land and sea borders.",
  },
  {
    id: 17,
    name: "EID PKI SUITE",
    title: "Public Key Infrastructure",
    description: "The key to secure digital processes",
  },
  {
    id: 18,
    name: "GLOBALTESTER",
    title: "Automated test tool for conformity testing",
    description:
      "The world’s unique open source-based tool for conformity testing of smart cards and readers",
  },
  {
    id: 19,
    name: "GET PLATINUM EDITION",
    title: "Readout tool for sovereign ID documents",
    description: "Reliable and secure readout of sovereign documents",
  },
  {
    id: 20,
    name: "HARDWARE SECURITY MODULES (HSM)",
    title: "Support with selection and integration",
    description: "",
  },
];

const RISS = [
  {
    id: 1,
    name: "Digital document management for classified information",
    desc: "Less workload more collaboration highest security",
  },
  {
    id: 2,
    name: "Accomplishing more secure police networks with better performances",
    desc: "VPN gateway for protected data exchange",
  },
  {
    id: 3,
    name: "Mobile identity check and verification",
    desc: "For modern police work",
  },
  {
    id: 4,
    name: "Highly secure stationary and mobile workstations",
    desc: "All-in-one solution for confidential data processing",
  },
  {
    id: 5,
    name: "Stationary, mobile and automated border control",
    desc: "Secure, efficient and future-proof air, land and sea borders",
  },
  {
    id: 6,
    name: "High-quality capture and processing of biometric data",
    desc: "Successful installation of key biometric projects",
  },
];

const GBM = [
  {
    id: 1,
    name: "Secure mobile workplaces for government employees",
    desc: "Our proven SINA architecture for the protection of classified information",
  },
  {
    id: 2,
    name: "E-file for sensitive data",
    desc: "A secure environment for processing your sensitive e-files",
  },
  {
    id: 3,
    name: "Protecting digital identities",
    desc: "From access to citizen portals to employee access permissions",
  },
  {
    id: 4,
    name: "IT baseline protection",
    desc: "With our ISMS and IT baseline protection consulting you are always safe with your security concepts",
  },
];

const HEALTH = [
  {
    id: 1,
    title: "ACCESS TELEMATICS INFRASTRUCTURE",
    sub: "There is no one way to TI.",
    desc: "But there is the right solution for every requirement profile.",
  },
  {
    id: 2,
    title: "MEDICAL SECURITY",
    sub: "The cloud is becoming inevitable for everyone.",
    desc: "So is the question of secure connectivity.",
  },
];

const DS = [
  {
    id: 1,
    title: "SINA",
    sub: "All-in-one solution to Process classified date",
    desc: "The holistic IT security solution for a modern working day with classified information up to SECRET",
  },
  {
    id: 2,
    title: "SINA WORKFLOW",
    sub: "Less effort-more collaboration",
    desc: "The first and digital management of classified information up to SECRET",
  },
  {
    id: 3,
    title: "SECUSTACK",
    sub: "For digital sovereignty and transparency in the cloud",
    desc: "Full control over data in the private cloud",
  },
];

const IUM_ONE = [
  {
    id: 1,
    title: "EDGE",
    sub: "Overall solution for edge computing",
    desc: "IIoT gateway, edge computing platform and firewall with network monitoring in one.",
  },
  {
    id: 2,
    title: "MONITOR",
    sub: "The early warning system for your network",
    desc: "Prevention and detection of cyber attacks",
  },
  {
    id: 3,
    title: "EID PKI SUITE",
    sub: "Public Key Infrastructure",
    desc: "The key to secure digital processes",
  },
  {
    id: 4,
    title: "CRYPTOSERVICES",
    sub: "Secure key management and crypto services",
    desc: "Flexible backend solutions for the industry",
  },
  {
    id: 5,
    title: "SINA WORKSTATION S",
    sub: "Secure remote access",
    desc: "Secure remote maintenance - for operators, service providers and manufacturers",
  },
  {
    id: 6,
    title: "STASHCAT",
    sub: "Secure messaging",
    desc: "Secure messaging for companies",
  },
];

const IUM_TWO = [
  {
    id: 1,
    title: "INCIDENT HOTLINE",
    sub: "Incident response",
    desc: "Being able to respond quickly to hacking and cyber attacks - the direct line to incident response experts.",
  },
  {
    id: 2,
    title: "MONITOR",
    sub: "Raising awareness as a preventive foundation",
    desc: "Employees can recognize the benefits of cyber security and change their behavior on their own.",
  },
  {
    id: 3,
    title: "BCMS",
    sub: "Secure your business operations",
    desc: "Disruptions and crises can hit a company at any time. The best protection lies in prevention.",
  },
  {
    id: 4,
    title: "ISMS",
    sub: "The modular system for protecting sensitive data",
    desc: "We enable certification according to ISO 27001 on the basis of IT-Grundschutz.",
  },
  {
    id: 5,
    title: "CYBERSECURITY SITUATION",
    sub: "Cybersecurity Situation",
    desc: "To protect your business and your customers, it is critical to consider cybersecurity early on.",
  },
  {
    id: 6,
    title: "STASHCAT",
    sub: "Audit preparations and certification audits",
    desc: "Support in audit preparation and monitoring in the context of certification audits.",
  },
  {
    id: 7,
    title: "DSMS",
    sub: "Effective data protection support",
    desc: "Digitization, cloud computing, and big data pose increasingly complex challenges for data protection.",
  },
  {
    id: 8,
    title: "RISK MANAGEMENT",
    sub: "Risk analysis",
    desc: "The core of information security, business continuity management and data protection.",
  },
  {
    id: 9,
    title: "SECURITY ANALYSIS & PENETRATION TESTS",
    sub: "",
    desc: "Identify vulnerable system points and take appropriate protective measures.",
  },
];

const CV = [
  {
    id: 1,
    title: "PUBLIC CLOUD",
    sub: "",
    desc: "IT infrastructure and services that are accessible via the Internet and can be used by multiple organizations.",
  },
  {
    id: 2,
    title: "PRIVATE CLOUD",
    sub: "",
    desc: "An exclusive cloud infrastructure available only to one organization, featuring enhanced security and control over data.",
  },
  {
    id: 3,
    title: "HYBRID CLOUD",
    sub: "",
    desc: "Public + private cloud combined, offers flexibility and cost savings. Sensitive data securely into the private cloud, non-sensitive workloads use public cloud services.",
  },
  {
    id: 4,
    title: "ISMS",
    sub: "",
    desc: "Public + private cloud combined, offers flexibility and cost savings. Sensitive data securely into the private cloud, non-sensitive workloads use public cloud services.",
  },
];

export {
  INDUSTRIES,
  SERVICES,
  RISS,
  GBM,
  HEALTH,
  DS,
  IUM_ONE,
  IUM_TWO,
  CV,
};
