import React from "react";
import { Form, Input, Select } from "antd";
import { Helmet } from 'react-helmet';
import Main from "../../layout/Main";
import "./styles.scss";
import SecuLeftRedBg from "../../assets/imgs/secu-left-red-bg.png";
import SecuRightGrill from "../../assets/imgs/secu-right-grill.png"
import SecuMap from "../../assets/imgs/secu-map.png";
import { Field, Formik } from "formik";
import * as yup from "yup";
import FieldContainer from "../../components/Inputs/FIeldContainer";
import SecuButton from "../../components/SecuButton";
const { TextArea } = Input;
const values = {
  service: undefined,
  fullname: "",
  email: "",
  companyName: "",
  message: "",
};

const ContactUs = () => {
  const [form] = Form.useForm();

  const createUser = (values, { resetForm }) => { };
  return (
    <Main>
      <Helmet>
        <title>Contact Us | secugame</title>
        <meta name="keywords" content="New Era" />
      </Helmet>
      <div className={"min-h-screen h-full overflow-x-hidden"}>
        <img className="h-[50rem] top-[-15rem] bottom-0 left-[-10rem] right-0 mx-auto absolute z-[200]" src={SecuLeftRedBg} alt="SecuLeftRedBg" />
        <section className="relative">
          <div className="mt-[100px] mb-[97px] container">
            <div className="w-full flex flex-col sm:justify-center sm:items-center relative z-[200]">
              <h4 className="max-w-[774px] lg:text-[100px] sm:text-[60px] text-[20px] text-white sm:text-center font-light">
                Contact Us
              </h4>
              <img className="sm:h-[402px] h-[202px] absolute right-0 left-0 sm:top-[-7rem] top-[-1rem] bottom-0 mx-auto" src={SecuRightGrill} alt="SecuRightGrill" />
            </div>
            <div className="w-full lg:flex lg:justify-center sm:mt-0 mt-[5rem]">
              <div className="lg:w-1/2 sm:mt-[3rem]">
                <div className="w-full flex lg:justify-center sm:justify-around justify-between relative z-[200]">
                  <div>
                    <p className="sm:text-[24px] text-[14px] text-primary-500">ABUJA OFFICE</p>
                    <p className="sm:text-[18px] text-[12px] text-white mt-[9px]">Block 58, Flat 2, O.A.U. Quarters, Kashim<br />Ibrahim Way, Maitama, Abuja, FCT, Nigeria</p>
                    <p className="sm:text-[18px] text-[12px] text-white mt-[9px]">Phone: +234 809 990 8643<br />E-Mail: info@secugame.ng</p>
                  </div>
                  {/* <div className="sm:text-left text-right">
                    <p className="sm:text-[24px] text-[14px] text-primary-500">LAGOS OFFICE</p>
                    <p className="sm:text-[18px] text-[12px] text-white mt-[9px]">Kurfürstenstraße 58<br />45138 Essen</p>
                    <p className="sm:text-[18px] text-[12px] text-white mt-[9px]">Phone: +49 (0) 201 5454-0<br />E-Mail: info(at)secunet.com</p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="w-full lg:flex items-center">
            <div className="lg:w-1/2">
              <img className="w-full secu-transition" src={SecuMap} alt="SecuMap" />
            </div>
            <div className="lg:w-1/2 lg:pl-[46px] lg:block flex lg:mt-0 mt-[5rem]">
              <div className="lg:max-w-[520px] w-full lg:px-0 sm:px-[5rem] px-[2rem]">
                <p className="lg:text-[42px] sm:text-[32px] text-[22px] text-white font-light mb-[10px] sm:text-left text-center">Get in touch</p>
                <Formik
                  initialValues={values}
                  onSubmit={createUser}
                  enableReinitialize={true}
                  validationSchema={validator}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Form onFinish={handleSubmit} form={form}>
                      <div className={"mb-4"}>
                        <FieldContainer iconPlacementCss={"iconCss"}>
                          <Form.Item
                            name={"service"}
                            help={touched.service && errors.service ? errors.service : ""}
                            validateStatus={
                              touched.service && errors.service ? "error" : "success"
                            }
                          >
                            <Field>
                              {({ form: { setFieldValue } }) => (
                                <Select
                                  value={values?.service || undefined}
                                  onBlur={handleBlur}
                                  className={"form-field_input_2"}
                                  onChange={(e) => {
                                    setFieldValue("service", e);
                                  }}
                                  placeholder="Select Service"
                                  options={[
                                    { value: "Digital document management for classified information", label: "Digital document management for classified information" },
                                    { value: "Accomplishing more secure police networks with better perf…", label: "Accomplishing more secure police networks with better perf…" },
                                    { value: "Mobile identity check and verification", label: "Mobile identity check and verification" },
                                    { value: "Highly secure stationary and mobile workstations", label: "Highly secure stationary and mobile workstations" },
                                    { value: "Stationary, mobile and automated border control", label: "Stationary, mobile and automated border control" },
                                    { value: "High-quality capture and processing of biometric data", label: "High-quality capture and processing of biometric data" },
                                    { value: "Secure mobile workplaces for government employees", label: "Secure mobile workplaces for government employees" },
                                    { value: "E-file for sensitive data", label: "E-file for sensitive data" },
                                    { value: "Protecting digital identities", label: "Protecting digital identities" },
                                    { value: "IT baseline protection", label: "IT baseline protection" },
                                    { value: "Access Telematics Infrastructure", label: "Access Telematics Infrastructure" },
                                    { value: "Medical Security", label: "Medical Security" },
                                    { value: "SINA", label: "SINA" },
                                    { value: "SINA Workflow", label: "SINA Workflow" },
                                    { value: "SECUSTACK", label: "SECUSTACK" },
                                    { value: "Solutions for Industry 4.0 and IIoT", label: "Solutions for Industry 4.0 and IIoT" },
                                    { value: "Consulting for Industry 4.0 and IIoT", label: "Consulting for Industry 4.0 and IIoT" },
                                    { value: "Cloud Variant", label: "Cloud Variant" },
                                  ]}
                                />
                              )}
                            </Field>
                          </Form.Item>
                        </FieldContainer>
                      </div>
                      <div className={"mb-4 w-full flex gap-x-4"}>
                        <div className="w-1/2">
                          <FieldContainer iconPlacementCss={"iconCss"}>
                            <Form.Item
                              name={"fullname"}
                              help={
                                touched.fullname && errors.fullname
                                  ? errors.fullname
                                  : ""
                              }
                              validateStatus={
                                touched.fullname && errors.fullname
                                  ? "error"
                                  : "success"
                              }
                            >
                              <Input
                                type={"text"}
                                className={"form-field_input_2"}
                                placeholder={"Enter Name"}
                                value={values?.fullname || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Form.Item>
                          </FieldContainer>
                        </div>
                        <div className="w-1/2">
                          <FieldContainer iconPlacementCss={"iconCss"}>
                            <Form.Item
                              name={"email"}
                              help={touched.email && errors.email ? errors.email : ""}
                              validateStatus={
                                touched.email && errors.email ? "error" : "success"
                              }
                            >
                              <Input
                                type={"email"}
                                className={"form-field_input_2"}
                                placeholder={"Enter Email"}
                                value={values?.email || ""}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Form.Item>
                          </FieldContainer>
                        </div>
                      </div>
                      <div className={"mb-4"}>
                        <FieldContainer iconPlacementCss={"iconCss"}>
                          <Form.Item
                            name={"companyName"}
                            help={
                              touched.companyName && errors.companyName ? errors.companyName : ""
                            }
                            validateStatus={
                              touched.companyName && errors.companyName ? "error" : "success"
                            }
                          >
                            <Input
                              type={"text"}
                              className={"form-field_input_2"}
                              placeholder={"Company Name"}
                              value={values?.companyName || null}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Form.Item>
                        </FieldContainer>
                      </div>
                      <div className={"mb-4"}>
                        <FieldContainer iconPlacementCss={"iconCss"}>
                          <Form.Item
                            name={"message"}
                            help={
                              touched.message && errors.message ? errors.message : ""
                            }
                            validateStatus={
                              touched.message && errors.message ? "error" : "success"
                            }
                          >
                            <TextArea
                              rows={6}
                              type={"text"}
                              className={"form-field_input_2"}
                              placeholder={"Your Message"}
                              value={values?.message || null}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Form.Item>
                        </FieldContainer>
                      </div>
                      <div className={"my-6 flex sm:justify-start justify-center sm:mt-0 mt-20"}>
                        <SecuButton
                          state={"PRIMARY"}
                          text={"Send"}
                          type={"button"}
                          size={"SMALL"}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Main>
  );
};

const validator = yup.object().shape({
  service: yup.string().required("Role is required"),
  fullname: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  companyName: yup.string().required("Company name is required"),
  message: yup.string().required("Company name is required"),

});

export default ContactUs;