const navs = [
  {
    name: "Home page",
    route: "/",
    id: "home",
  },
  {
    name: "Industries",
    route: "/industries",
    id: "industries",
  },
  {
    name: "About Us",
    route: "/about",
    id: "aboutUs",
  },
  {
    name: "Services",
    route: "/services",
    id: "services",
  },
  {
    name: "Contact Us",
    route: "/contact",
    id: "contact",
  },
];

export { navs };
